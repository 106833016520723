<template>
	<div class="row flex-column-reverse flex-md-row" id="complete-terminaison-determinant-table">
		<div class="col-md-9 text-center mr-auto">
			<form class="inner multi not-solution" id="game">
				<div class="question">
					<div id="libelle" class="row p-2" v-html="libelle"> </div>
					<div class="content-exercise">
						<table id="maison" class="table table-bordered">
							<header-cels :table-cels="tableCels">
									<!-- <th class="table-info border-perso" v-if="fail_response">Réponses</th> -->
							</header-cels>
							<tbody class="corps input-field" id="body-reponse">
								<tr v-for="(item,i) in form" :id="'tr-'+i" class="phrase">
									<td :rowspan="form.length" v-if="i==0" class="pt-2">
										<div class="d-flex flex-column justify-content-around flex-wrap" id="completer">
											<label v-for="(item, i) in tab_prop" :class="['ans border', item.type]" :draggable="!item.use&&
											!(trouve||fail_response)" ondragstart="drag(event)" :id="'lbl_'+(item.det?'det':'term')+'-'+i">
												<input type="text" :value="item.mot" :readonly="true"/>
												<div :class="['m-auto mot',{barre:item.use}]" draggable="false">{{item.mot}}</div>
											</label>
										</div>
									</td>
									<td v-for="(cel,j) in tableCels.map(el=>get_id_cel(el))" :id="cel+'-'+i" :class="[item.ask!=cel?'singulier border-perso':'completer singulier border-perso']">
										<div>
											<div v-if="item.ask!=cel">{{item[cel]}}</div>
											<div v-else>
												<div :class="['reponse d-inline-block reponse-det','reponse-'+cel]" :id="'reponse-det-'+i" @dragover.self="alowDrop" @drop.prevent.self="copy"></div>&nbsp;{{item.reponse.corps}}<div :class="['reponse reponse-term d-inline-block', 'reponse-'+cel]" :id="'reponse-term-'+i" @dragover.self="alowDrop" @drop.prevent.self="copy"></div>
												<div v-if="fail_response">
													<span class="text-danger">{{item.reponse.det}}</span>{{(item.reponse.det.includes("'")?"":" ")}}<span>{{item.reponse.corps}}</span><span class="text-danger">{{item.reponse.term}}</span>
												</div>
											</div>
										</div>
									</td>
									<td v-show="fail_response" class="text-success border-perso">
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div id="comment" v-html="label" v-if="empty_response"></div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		props:{libelle:{required:true, type:String}},
		data(){
			let data=datas(questionnaire);
			data.tableCels=tableCels;
			return data;
		},
		created(){
			exercice_vue=this;
			let tab_prop={term:[],det:[]};
			questionnaire=questionnaire.map(function(elem,ind) {
				elem.ask=get_id_cel(tableCels[rn(0,tableCels.length-1)]);
				let item=elem[elem.ask];
				elem.reponse={det:elem[elem.ask].substr(0,item.indexOf(" ")),term:elem[elem.ask].slice(-2),corps:elem[elem.ask].substring(item.indexOf(" "),item.length-2)};
				tab_prop.term.push(elem.reponse.term);
				tab_prop.det.push(elem.reponse.det);
				return elem;
			});
			this.tab_prop=tab_prop.term.map((el=>{return {mot:el, type:'term'}})).concat(tab_prop.det.map(el=>{return {mot:el,type:'det'}})).sort_rn();
		},
		methods:{
			submit(ev){
				this.empty_response=false;
				submited=[];
				if($("#body-reponse tr .reponse").not(":has('label.ans input')").addClass('empty-reponse').length>0){
					this.empty_response=true;
					this.label="Tu dois remplir toutes les cases vides!";
					return 0;
				}

				let ca=0, fr=false;
				$("#body-reponse tr").each(function(index, el) {
					if($(el).find('.reponse-term label.ans input').val()==questionnaire[index].reponse.term){
						ca++;
						$(el).find('.reponse-term .mot').after("<span class='valid ml-n2'></span>")
					}else{
						$(el).find('.reponse-term .mot').addClass('barre');
						fr=true;
					}
					if($(el).find('.reponse-det label.ans input').val()==questionnaire[index].reponse.det){
						ca++;
						$(el).find('.reponse-det .mot').after("<span class='valid ml-n2'></span>")
					}else{
						$(el).find('.reponse-det .mot').addClass('barre');
						fr=true;
					}
				});
				this.correct_answer=ca;
				for(let i=0;i<submited.length;i++){
					if(submited[i].singulier===questionnaire[i].singulier && submited[i].pluriel===questionnaire[i].pluriel){
						this.correct_answer+=2;
						$("#tr-"+i).find(".completer label .mot").addClass("valid");
					}else{
						item=questionnaire[i].ask?questionnaire[i].pluriel:questionnaire[i].singulier;
						if($("#tr-"+i).find("label.det input").val()!==item.substr(0,item.indexOf(" "))){
							$("#tr-"+i).find("label.det .mot").addClass("barre");
						}else{
							$("#tr-"+i).find("label.det .mot").addClass("valid");
							this.correct_answer++;
						}
						if($("#tr-"+i).find("label.term input").val()!==item.slice(-2)){
							$("#tr-"+i).find("label.term .mot").addClass("barre");
						}else{
							$("#tr-"+i).find("label.term .mot").addClass("valid");
							this.correct_answer++;
						}
						this.fail_response=true;
					}
				}
				if(this.fail_response=fr){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
		},
	};
</script>