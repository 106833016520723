<template>
	<a :href="route+'/exercice-'+ind" class="card mb-4 text-center">
		<h4 class="card-title mb-0 pt-3">{{theme}}</h4>
		<div class="card-body">
			<div class="card text-center">
				<img :src="'/public/assets/images/exo/'+color+ind+'.png'" :alt="'Leçon et Exercices '+theme+' - CE1 - Exercice-'+ind" class="card-img">
				<div class="card-img-overlay">
					<div class="d-flex flex-column">
						<h5>Exercice {{ind}}</h5>
					</div>
				</div>
			</div>
		</div>
	</a>
</template>
<script type="text/javascript">
	export default{
		data(){return {color:color+"-", route:window.location.pathname.replace(/#.*$/g,'')}
		},
		props:{
			ind:{type:Number, required:true},
			theme:{type:String,default:""}
		},
		created(){
		}
	};
</script>