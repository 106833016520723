<template>
	<div class="row flex-column-reverse flex-md-row" id="complete-texte-input">
	<div class="col-md-9 text-centerd-flex">
		<form class="inner" id="game" @submit="submit(event)" action="#_">
			<div class="question">
					<div id="libelle" v-html="libelle"> </div>
					<div class="content-exercice">
						<div id="texte" v-html="texte"></div>
					</div>
				<div id="noms" class="row input-field mt-3">
				</div>
				<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
		</form>
		<bottom-section :fail="fail_response" :submit="submit" :trouve="trouve" :score="score" :end="end" :matiere="matiere" :passed="passed"></bottom-section>
	</div>
	<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
</div>
</template>
<script>
/*
*
*if createdMetod=conj:texte, tabVerb:[{inf:"",conj:""}],
	temps,groupe
	tabConjugue:[{verbe:"",conj:["",""]}]
	we can set the array proposition of each verbe by setting tabConjugue:[{verbe:"",conj:[]}] or 
	we can give a group and time of conjugaison and the program will conjugue all verbs of tabVerb automatically
*
*else tabPropos:["","",---""] is a list of words to remove from text wich the user must answer
*
*
*
*/
	var propositions;
	export default{
		props:{
			createdMethod:{required:false, default:"purp"},
			libelle:{type:String, required:false, default:"Sélectionne le mot correspondant pour compléter le texte."},
			oneVerb:{type:Boolean, required:false, default:false}
		},
		data(){
			let dat=datas([""]);
			dat.matiere=matiere;
			return dat;
		},
		created(){
			let txt;
			if(this.createdMethod=="purp"){
				txt=this.created_purpose();
			}else if(this.createdMethod=="conj"){
				txt=this.created_select_conjugue();
			}
			propositions.sort((a,b)=>a.i-b.i);
			this.good_answer=propositions.map(el=>{return {ans:el.mot}});
			this.tab_prop=propositions.slice().sort_rn();
			this.texte=regulariser(txt);

			exercice_vue=this;
		},
		mounted(){
			$(".reponse .input").on("change",(ev)=>{
				exercice_vue.answered=$(".reponse .input").map((el,ind)=>$(ind).val()).get().filter(e=>e.length>1).length;
				exercice_vue.$forceUpdate();
			});
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				let submited=[];
				this.empty_response=false;
				if($("#texte .reponse .input").filter((el,ind)=>$(ind).val().length>1).get().length<propositions.length){
					this.empty_response=true;
					play(au.empty);
					this.label="Tu dois glisser un nom dans chaque case vide!";
					return 0;
				}
				submited=$("#texte .reponse .input").map((el,ind)=>$(ind).val()).get()
				$(".inf").remove();
				$("#texte .reponse .input").prop("disabled",true).addClass('w-0');
				for(let i=0;i<submited.length;i++){
					if(submited[i]===propositions[i].mot){
						this.correct_answer++;
						$("#texte .reponse .input").eq(i).addClass("text-orange").after("<span class='valid ml-n2 mr-2'></span>");
					}else{
						this.fail_response=true;
						$("#texte .reponse .input").eq(i).addClass("barre").after("<span class='text-success mx-1'>"+propositions[i].mot+"</span>");
					}
					$("#texte .reponse").eq(i).addClass("bg-white border-0 w-0").removeClass("drop").find(".input").addClass("border-0");
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					play(au.success);
					this.trouve=true;
				}
				make_score(this);
			},
			created_select_conjugue(){
				let tabText=texte.split(" "), tabPropos=tabVerb.map(el=>el.conj);
				propositions=tabText.map((el,i)=>{
					tabText[i]+=" ";
					return {mot:el,i:i}
				}).filter(el=>tabPropos.includes(el.mot));

				for(let i=0;i<propositions.length;i++){
					let h="", inf=tabVerb[tabVerb.map(el=>el.conj).indexOf(propositions[i].mot)].inf,conj;

					propositions[i].mot=propositions[i].mot.replace(/_/g,' ');
					if(this.oneVerb){
						conj=conjugaison(verbe,temps,groupe);
					}else{
					 	conj=(typeof tabConjugue!=="undefined")?
					 		tabConjugue.filter(el=>el.verbe==inf).map(el=>el.conj).flat():
					 		conjugaison(inf,temps,groupe);
					}
					conj.push(propositions[i].mot);
					conj=conj.duplicate().sort_rn();
					let conjugue="<option value='' disabled selected></option>"+conj.map(el=>"<option value='"+el+"'>"+el+"</option>").join(" ");
					if(propositions[i].i==0||tab_ponctuation.includes(tabText[propositions[i].i-1].slice(-2,1))){
						h="<div class='reponse maj border-0' id='reponse-"+i+"'><select type='text' class='mot input' onchange='updtAnswered(event)'>"+conjugue+"</select></div> ";
					}else{
						h="<div class='reponse border-0' id='reponse-"+i+"'><select type='text' class='mot input' onchange='updtAnswered(event)'>"+conjugue+"</select></div> ";
					}
					tabText[propositions[i].i]=h+"<span class='inf'>("+inf+")</span> ";
				}
				return tabText.join("");
			},
			created_purpose(){
				let tabText=texte.split(" ");
				propositions=tabText.map((el,i)=>{
					tabText[i]+=" ";
					return {mot:el,i:i}
				}).filter(el=>tabPropos.includes(el.mot)).sort_rn().slice(0, 10);
				for(let i=0;i<propositions.length;i++){
					let h="";
					if(propositions[i].i==0||tab_ponctuation.includes(tabText[propositions[i].i-1].slice(-2,1))){
						h="<div class='reponse maj drop d-inline-block ' id='reponse-"+i+"'><input type='text' class='mot'></div> ";
					}else{
						h="<div class='reponse drop d-inline-block' id='reponse-"+i+"'><input type='text' class='mot'></div> ";
					}
					tabText[propositions[i].i]=h;
				}
				return tabText.join("");
			},
			updtAnswered(ev){
				this.answered=$(".reponse .input").map((el,ind)=>$(ind).val()).get().filter(e=>e.length>1).length;
				this.$forceUpdate();
			}
		}
	};
</script>