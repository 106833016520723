<template>
	<div>
		<div class="py-3 px-2 text-left" style="text-align-last:initial" id="choose-good-text-for-text">
			<div class="main-quiz-holder">
				<div class="slide-container d-block py-3">
					<div class="py-3">
						<head-exercise :afficher-reponse="afficheReponse" :reponse-vraie="reponseVraie" :reponse-fausse="reponseFausse" :rejouer="rejouer"></head-exercise>
						<div class="code2">
							<div id="question">
								<div class="featureText1 ml-5" v-html="question"></div>
								<div class="d-flex justify-content-center">
									<div class="col-md-3 operation">
										<div v-for="(item,i) in operandes" class="my-0 position-relative">
											<div class="opnum1">{{item}}</div>
											<div class="signe position-absolute" v-if="i<operandes.length-1">{{signe}}</div>
										</div>
										<div class="opnum2"></div><br>
										<input type="text" autofocus="autofocus" class="form-control text-right" @keyup="controlInput" v-model="reponse" id="input-reponse" :readonly="repondu" :disabled="repondu" autocomplete="off">
									</div>
								</div>
							</div>
							<div class="result-container notice col-md-5" v-show='reponseVide'>Entre une réponse valide!</div>
						</div>

						<div style="font-size:1.2em">
							<p style="color:#999;"><em v-html="notice"></em></p>
							<p class="my-3" v-show="afficherReponse">
								La bonne réponse est : <span class="text-success" v-html="bonneReponse"></span>
							</p>
						</div>
						<div class="nav-container">
							<button class="btn next final" @click="soumettre" type="button"></i>Valider </button>
						</div>
						
					</div>
					<navigation-frame :question-suivante="questionSuivante"></navigation-frame>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/*
*
*questionnaire:[{libelle:'',solution:Xn, propositions:[x1,x2,---]}]
*
*
*
*
*
*/
	export default{
		props:{
			input:{type:Boolean, required:false, default:false},
			notice:{type:String, required:false, default:''}
		},
		data:function () {
			return {
				question:'',
				operandes:[],
				reponse:'',
				reponseVide:false,
				reponseFausse:false,
				reponseVraie:false,
				bonneReponse:0,
				repondu:false,
				index:0,
				afficherReponse:false,
			}
		},
		created(){
			this.initialiser();
		},
		methods:{
			questionSuivante(e){
				this.rejouer();
				this.index=e==1? (this.index+1) % questionnaire.length: 
					e==-1? Math.max(this.index-1,0):
					rn(0,questionnaire.length-1) ;	
				this.initialiser();
			},
			initialiser(){
				let i=this.index;
				if(this.input){
					this.operandes=questionnaire[i].operandes.sort_gt();
					// this.operandes.push(rn(this.operandes[0],this.operandes[1]));
				}else{
					this.propositions=questionnaire[i].propositions;
				}
				this.signe=questionnaire[i].signe;
				this.bonneReponse=this.signe=='+'?questionnaire[i].operandes.reduce((a,b)=>a+b, 0):questionnaire[i].operandes.reduce((a,b)=>a-b);
				this.question=this.signe=='+'?"Additionne.":'Soustraire.';
			},
			rejouer(){
				this.reponse='';
				this.reponseFausse=false;
				this.reponseVraie=false;
				this.reponseVide=false;
				this.repondu=false;
				this.afficherReponse=false;
				document.getElementById('input-reponse').focus();
			},
			soumettre(ev){
				if(this.reponse==''){
					this.reponseVide=true;
					play(au.empty);
					return 0;
				}
				this.repondu=true;
				if(this.reponse==this.bonneReponse){
					this.reponseVraie=true;
					play(au.success);
				}else{
					this.reponseFausse=true;
					play(au.fail);
				}
			},
			controlInput(ev){
				if((ev.keyCode<48 || ev.keyCode>57 )){
					ev.preventDefault();
				}
				let elem=document.getElementById(ev.target.id); 
				elem.focus();
				elem.setSelectionRange(0,0);
			},
			afficheReponse(){
				this.afficherReponse=true;
			}
		},
		watch:{
		}
	};
</script>