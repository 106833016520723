<template>
	<div class=poster-subject>
		<a :href="link" class="card mb-4 text-center">
			<img :src="'/public/assets/images/'+src+'.png'" :alt="title+' - '+src.replace(/\/|-/g,' - ')" class="card-img-top">
			<div class="card-body" v-if="title.length>0">
				<h5>{{title}}</h5>
			</div>
		</a>
	</div>
</template>
<script>
	export default{
		props:{
			link:{type:String, required:true},
			src:{type:String, required:false, default:false},
			title:{type:String, required:false,default:''}
		}
	};
</script>