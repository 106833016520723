<template>
	<div class="row flex-column-reverse flex-md-row" id="comparer-ranger">
		<div class="col-md-9 text-center">
			<form class="inner" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle">{{libelle}}</div>
					<div class="multi" v-if="!input">
						<div>
							<div class="row m-0 corps input-field justify-content-center" id="cases-responses">
								<div :id="'corps-'+(i+1)" class="col square px-1" v-for="i in tab_prop.length" style="max-width:1n0px">
									<div class="p-0" @dragover.self="alowDrop" @drop="move"></div>
								</div>
							</div>
							<div class="d-flex justify-content-center input-field mx-0 mt-3 border border-info py-3" @dragover.self="alowDrop" @drop="move" id="input-field">
								<label class="ans p-1 border-img m-1" v-for="item in tab_prop" :draggable.self="!(trouve||fail_response)" ondragstart="drag(event)" :id="'lbl-'+item">
									<input type="number" :value="item" name="s1" :readonly="true"/>
									<img :src="'/public/assets/images/fr/mathematiques/les-nombres/nombres-png/'+item+'.png'" draggable='false' :id="'img'+item"/>
								</label>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="row no-gutters input-field mx-auto px-4">
							<div v-for="(item,index) in tab_prop" :class="[item.class,'col ans p-3']">
								<div id="cases-responses" v-if="!item.input">
									<input type="number" :name="'s-'+index" class="m-0 response rounded" value="" :id="'response-'+index" :min="min" :max="max" @keyup="next_input" v-model="response[index]" size="3">
									<div v-show="fail_response">{{item.val}}</div>
								</div>
								<div v-else v-show="true" class="h-100">{{item.val}}</div>
							</div>
						</div>					
					</div>
					<solution-exercice :fail-response='fail_response' :answer="answer"></solution-exercice>
					<div id="comment" v-if="empty_response">{{input?"Tu dois remplir toutes les cases vides":"Tu dois ranger tous les éléments! "}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed" :matiere="matiere" :nbre-question="10" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template> 


<script>



/*
*
*here libelle is prop.
*questonnaire=[{}]
*
*/
	export default{
		props:{
			libelle:{required:false, type:String, default:"Comparer et ranger les nombres"},
			min:{type:Number, required:false, default:1},
			max:{type:Number, required:true},
			croissant:{type:Boolean, required:false, default:true},
			input:{type:Boolean, required:false, default:false},
			nbInput:{type:Number,required:false,default:6}
		},
		data(){
			let data=datas(questionnaire);
		    data.label="";
		    data.goodAnswer=[];
		    data.response=[];
			return data;
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		methods:{
			next(){
				$("#cases-responses label").detach().appendTo('#input-field');
				next_qst(this);
				$("#input-field").find("span").remove();
				$(".way").remove();
			},
			next_input(ev){
				next_input(ev,this.nbInput);
			},
			initialiser(ev){
				this.goodAnswer=[];
				if(!this.input){
					let tab=[], nbre=Math.min(5,this.max-this.min+1);
					for(let i=this.min; i<=this.max; i++){
						tab.push(i);
					}
					this.tab_prop=tab.sort_rn().slice(0,nbre);
					this.goodAnswer=this.tab_prop.slice().sort_lt();
					this.ab=this.croissant?"<strong>petit</strong> au plus <strong>grand</strong>: ordre <u>croissant</u>":"<strong>grand</strong> au plus <strong>petit</strong>: ordre <u>décroissant<u>";
					var icon=(this.croissant)?"fa-angle-left":"fa-angle-right";
					this.answer="<div class='m-0 d-flex justify-content-center'>"+
					this.goodAnswer.map((el,i)=> "<div class='d-flex align-items-center my-auto'><div class='p-0'><img src='/public/assets/images/fr/mathematiques/les-nombres/nombres-png/" + el + ".png' class='w-100 h-100'></div><i class='fa fa-2x icon-compare "+icon+"' id='icon-compare-" + i + "'></i></div>") + "</div>";
				}else{	
					this.tab_prop=[];
					let temp, tab_prop=[],input=[],m=this.min,M=this.max,ans=rn(m,M),in_count=rn(1,2),
						submited=[],tab=["f0","da","ff"], n=this.nbInput;
					temp=rn(1,Math.max(n-M-ans,n));
					for(let i=0;i<n;i++){
						input.push(i<in_count);
					}
					input.sort_rn();
					for(let i=1;i<=n;i++){
						let te,t;
						do{
							te=rn(0,2);//couleur de fond
							t=(i==1)?false:(this.tab_prop[i-2].class==tab[te]);
						}while(t);
						this.tab_prop.push({val:ans+i-1, class:tab[te], input:input[i-1]});
						if(!input[i-1]){
							this.goodAnswer.push(ans+i-1);
						}
					}
					this.answer=this.goodAnswer.join(' ');
					this.response=this.goodAnswer.map(el=>'');
					$("input[type='number']").val('').first().focus();
				}
			},
			submit(ev){
				if(this.nbre_question-this.answered<1)return 0;
				if($("#cases-responses .square").not(':has("label input")').addClass('bg-warning').length>0 || 
					this.response.filter(el=>el.length<1).length>0
				){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				this.empty_response=false;

				let corrects=0,fails=false;
				$("#cases-responses input").map((i,el)=>{
					if($(el).val()==exercice_vue.goodAnswer[i]){
						corrects++;
					}else{
						fails=true;
						$(el).siblings('img').addClass('failed').parent().after("<div class='red-cross d-flex'><img src='/public/assets/images/croix.png' class='img-fluid my-auto mx-auto'></div>");
					}
					let t=this.goodAnswer.indexOf(parseInt($(el).val()));
					$(el).parent().append("<div class='way d-flex h-100 w-75'><span class='mt-auto ml-auto text-success'>"+this.goodAnswer[i]+"</span></div>");
				});
				this.correct_answer=corrects;
				if(this.fail_response=fails){
					$(".icon-compare").last().remove();
					play(au.fail);
				}else{
					play(au.success);
					this.trouve=true;
				}
				make_score(this);
				$(".input-field .ans > img, .input-field .ans .imgp").removeClass("bg-success");
			},
			move(ev){
				let idElt=ev.dataTransfer.getData("text/plain");
				$("#"+idElt).detach().appendTo(ev.target);
			}
		},
		watch:{
		}
	};
</script>