<template>
	<div class="navigation text-center mt-auto">
	 	<div class="d-flex mx-0 px-2 justify-content-around">
	 		<div class="nav-game nav-status flex-fill">
	 			<div class="h-100 w-100 d-flex flex-column justify-content-center">
		 			<div>{{passed}}  | {{nbreQuestion}} </div>
		 		</div>
	 		</div>
	 		<div class="d-flex btn next_game-link">
				<div class="btn-group" role="group" aria-label="First group" v-if="typeof(listExercices)!='undefined'&&listExercices.length>0">
					<span class="btn btn-secondary mr-5">Exercices </span> 
					<a :class="['btn btn-secondary mr-1',{active:index_exo-1==i}]" v-for="(item,i) in listExercices" :href="route+'/'+item+'#exercices'" :title="item">{{i+1}}</a>
				</div>
	 		</div>
	 	</div>
	</div>
</template>
<script>
	export default{
		props:{
			passed:{type:Number, required:false, default:1},
			nbreQuestion:{type:Number, required:true, default:1},
		},
		data(){
			let r=window.location.pathname, l;
			if(typeof listExercices =='undefined'){
				l=[];
			}else{
				l=listExercices;
			}
			console.log(l);
			return {
				index_exo:index_exo, 
				route:window.location.pathname.slice(0,r.lastIndexOf('/')),
				listExercices:l
			};
		},
	};
</script>