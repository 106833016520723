<template>
	<div class="row flex-column-reverse flex-md-row" id="choose-good-word-from-sentences">
		<div class="col-md-9">
			<form class="inner multi" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<ol id="phrases" class="input-field text-left">
						<li v-for="(ite,i) in form" :id="'phrase-'+i" class="phrase">
							<label :class="['px-0', classWords(item,'label')]" v-for="(item,j) in tab_prop[i]" :id="'lbl-'+i+j">
								<input type="radio" :name="'s'+i" :value=item v-model="response[i]" @change="set_answered" :disabled="trouve||fail_response||tab_ponctuation.includes(item)">
								<div class="mot" :id="'mot-'+i+j">{{item}}</div>
							</label>
						</li>
					</ol>
					<div id="comment" v-if="empty_response">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		data(){
			let data=datas(questionnaire);
			data.response=new Array(questionnaire.length);
			data.tab_prop=questionnaire.map(el=>el.phrase.split(" "));
			data.tab_ponctuation=tab_ponctuation.concat(",",":",";");
			data.libelle=libelle;
			return data;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				if(this.answered<questionnaire.length){
					this.empty_response=true;
					this.label="Tu dois faire un choix sur chaque ligne.";
					play(au.empty);
					return 0;
				}
				this.empty_response=false;
				for(let i=0;i<questionnaire.length;i++){
					let j=this.tab_prop[i].indexOf(this.response[i]);
					if(this.response[i]==questionnaire[i].answer){
						this.correct_answer++;
						$("#phrases .phrase").eq(i).find("input:checked+.mot").after("<span class='valid'></span>");
					}else{
						this.fail_response=true;
						$("#mot-"+i+j).addClass('barre');
						j=this.tab_prop[i].indexOf(questionnaire[i].answer);
					}
					$("#mot-"+i+j).addClass("cadre");
				}
				$(".phrase label").removeClass("label");
				if(this.fail_response){
					play(au.fail);
					this.comment_faux="Tu as manqué "+(questionnaire.length-this.correct_answer)+" questions.";
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
			set_answered(ev){
				this.answered=$("#phrases input:checked").length;
			},
		},
		created(){
		}
	};
</script>