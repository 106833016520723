<template>
	<div class="row flex-column-reverse flex-md-row" id="drag-drop-between-words-table">
		<div class="col-md-9 text-center d-flex flex-column justify-content-between">
			<form class="inner d-flex flex-column justify-content-between multi col-lg px-0" id="game" @submit="submit" action="#_">
				<div class="question col-lg d-flex">
					<div id="libelle" class="py-1" v-html="libelle"><br></div>
					<div class="row no-gutters">
						<div class="col-md-auto border mr-2 d-flex flex-column">
							<div ondragover="alowDrop(event)" @drop="remove" class="px-5 border text-danger border-danger">
								<i class="fa fa-trash"></i>
							</div>
							<div class="d-flex flex-column justify-content-around px-2 flex-fill py-4" id="mots">
								<label v-for="(item,j) in tab_mots.ne" class="label ne border" :id="'ne-'+j" draggable="true" ondragstart="drag(event)"><input type="text" :value="item" :disabled="trouve||fail_response" class=".input"><div class="mot px-1" draggable="false">{{item}}</div></label>

								<label v-for="(item,j) in tab_mots.pas" class="label pas border" :id="'pas-'+j" draggable="true" ondragstart="drag(event)"><input type="text" :value="item" :disabled="trouve||fail_response"><div class="mot p-1" :id="'mot-pas-'+j">{{item}}</div></label>
							</div>
						</div>
						<div class="table-responsive col-lg">
							<table class="table table-bordered border-perso my-auto">
								<thead>
									<tr class="text-center">
										<th class="table-info">Phrases</th>
									</tr>
								</thead>
								<tbody id="phrases" class="input-field">
									<tr v-for="(ite,i) in form" class="line">
										<td :id="'phrase-'+i" class="phrase py-1 text-left border-perso">
											<span v-for="(item,j) in tab_prop[i]" draggable="false" class="mot-phrase" :id="'mot-'+i+j"><span @drop="copy" ondragover="alowDrop(event)" class="drag">{{item}}</span><span v-if="j<tab_prop[i].length-1">&nbsp;</span></span><span class="mot-phrase" :id="'ponctuation-'+i" draggable="false"><span @drop="copy" ondragover="alowDrop(event)" class="col-lg pl-0 pr-3 drag">{{ite.phrase.slice(-1)}}</span></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>



/*
*
*questionnaire=[{phrase:" c1c2c3--cn"}] //toujours un espace avant la phrase
*
*
*/
	export default{
		data(){
			let data=datas(questionnaire);
			data.bubble=false;
			data.libelle=libelle;
			return data;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				$(".phrase").removeClass("border-warning");
				this.empty_response=false;
				let submited=$("#phrases .phrase").has("label").map((el,ind)=>$(ind).text().replace(/\u00A0/g, ' ')).get();
				if(submited.length<questionnaire.length){
					this.empty_response=true;
					play(au.empty);
					this.label="Complète toutes les phrases.";
					return 0;
				}
				for(let i=0;i<submited.length;i++){
					let s=submited[i], g=questionnaire[i].phrase.slice(1), coche=$("#ponctuation-"+i), phrase=$("#phrase-"+i);
					if(questionnaire[i].phrase.slice(1)==submited[i]){
						coche.addClass("valid");
						this.correct_answer++;
					}else{
						let s1=submited[i].toLowerCase(),g2=questionnaire[i].phrase.slice(1).toLowerCase(),
						slc=submited[i].toLowerCase().replace("n'","n' ").split(" "),
						glc=questionnaire[i].phrase.slice(1).toLowerCase().replace("n'","n' ").split(" "),
						sne=slc.includes("ne")?slc.indexOf("ne"):slc.indexOf("n'"),spas=slc.indexOf("pas"),
						gne=glc.includes("ne")?glc.indexOf("ne"):glc.indexOf("n'"),gpas=glc.indexOf("pas");
						if(sne!=gne||slc[sne]!=glc[gne]){
							$("#phrase-"+i+" label.ne").addClass("barre");
							$("#mot-"+i+gne).find("span.drag").prepend("<span class='mx-1 good'>"+glc[gne]+"</span>")
						}else{
							$("#phrase .phrase").eq(i).find("label.ne").after("<span class='valid'></span>")
							this.correct_answer+=0.5;
						}
						if(spas!=gpas){
							$("#phrase-"+i+" label.pas").addClass("barre");
							$("#mot-"+i+(gpas-1)).find("span.drag").prepend("<span class='mx-1 good'>"+glc[gpas]+"</span>")
						}else{
							$("#phrase .phrase").eq(i).find("label.pas").after("<span class='valid'></span>")
							this.correct_answer+=0.5;
						}
						this.fail_response=true;
						coche.addClass("fail");
					}
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
			copy(ev){
				let id_elt=ev.dataTransfer.getData("text/plain");
				let parent=ev.dataTransfer.getData("text/parent");
				let node_copy=$("#"+id_elt).clone();
				var phrase_elt=$(ev.target).parent().parent();
				if(parent!=="mots"){
					let phrase=$("#"+parent).parent();
					if(phrase.attr("id")!=phrase_elt.attr("id")){
					}else{
						put(ev,$("#"+id_elt).detach());
					}
					return 0;
				}
				if($(phrase_elt).find("#"+id_elt+"-copy").length){
					this.comment_false="Tu as deja ranger ce mot";
					return 0;
				}
				if(id_elt.includes("ne")&&phrase_elt.find(".ne").length){
					this.comment_false="les expressions (ne) et (n') ne vont pas dans la même phrase.";
					$("#bubble").detach().appendTo(phrase_elt).fadeIn(bubble.fi).find("div.texte-bubble").text("<< "+phrase_elt.find(".ne").text()+" >> existe déjà dans cette phrase. Complète la phrase avec \"pas\" ou supprime-le!");
					setTimeout(()=>$("#bubble").fadeOut(bubble.fo) ,bubble.timer);
					return 0;
				}
				if(id_elt.includes("pas")&&phrase_elt.find(".pas").length){
					$("#bubble").detach().appendTo(phrase_elt).fadeIn(bubble.fi).find("div.texte-bubble").text("<<"+phrase_elt.find(".pas").text()+">> existe déjà dans cette phrase. Complète avec \"ne\" ou \"n ' \"");
					setTimeout(()=>$("#bubble").fadeOut(bubble.fo) ,bubble.timer);
					return 0;
				}
				node_copy.removeClass("border col-12")
				node_copy.attr("id",id_elt+"-copy-"+phrase_elt.attr("id"));
				put(ev,node_copy);
			},
			remove(ev){
				remove(ev);
				this.answered=$("#phrases tr .phrase").has("label.ne").has("label.pas").length;
			},
			without_last(text){
				return text.without_last();
			},
			get_last(text){
				return text.get_last();
			}
		},
		created(){
			let tab=["ne ","n'","pas "], tab_prop=[];
			this.tab_mots={ne:["ne ","n'"],pas:["pas "]};
			this.tab_prop=questionnaire.map(el=>el.phrase.replace(/ ne | n'| pas /g, ' ').slice(1,-1).split(' '));
			exercice_vue=this;
		},
		watch:{}
	};
function put(ev,node_copy) {
	let aft=$(ev.target).parent().is(":last-child"),mot, mot_phrase,
	t=node_copy.text();
	if(t.includes(" ")){
		node_copy.addClass("mr-1");
	}else{
		node_copy.removeClass("mr-1");
	}
	mot=$(ev.target).parent().parent();
	mot_phrase=$(ev.target).parent();
	t=t.toLowerCase();
	if(mot_phrase.is(":last-child")){
		t=" "+t.replace(" ", '');
		node_copy.addClass("ml-1");
	}else{
		node_copy.removeClass("ml-1");
	}
	if(mot_phrase.is(":first-child")){
		t=t.capitalize();
	}else{}
	$(ev.target).before(node_copy.text(t));
	exercice_vue.answered=$("#phrases tr .phrase").has("label").length;
}
</script>