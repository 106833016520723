<template>
	<div class="row flex-column-reverse flex-md-row" id="solution-exercice">
		<div class="show-answer text-center my-2 py-2 w-100" v-if="failResponse	" id="solution-question"> 
			<span class="text-primary">Solution</span><hr class="solid mt-n1">
			La bonne réponse est <strong class="text-success" v-html="answer"></strong>
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			failResponse:{type:Boolean, required:true,default:false},
			answer:{type:String, required:true,default:""}
		},
		data(){
			return{};
		},
		created(){
		},
		methods:{
		},
		watch:{}
	};
</script>