<template>
	<div class="row flex-column-reverse flex-md-row" id="choose-radio-picture-string-random">
		<div class="col-md-9 text-center">
			<form class="inner" id="game">
				<div class="question" id="question">
					<div id="libelle">{{ab}}
					</div>
					<div class="content-exercice d-flex flex-column">
						<div class="flex-fill" id="sujet">
							<div v-if="!img" class="d-flex justify-content-between">
								<div class="d-flex justify-content-center px-2">
									<img :src="'/public/assets/images/le-verbe/'+form[index_qst].image+'.png'" class="">
								</div>
								<div class="flex-fill d-flex flex-column justify-content-around px-2" id="liste-propos">
									<div class="align-self-end">
									<label class="ans d-flex" :id="'label-img-'+i" v-for="(item,i) in tab_prop">
										<input type="radio" name="s2" :value="item.phrase" :disabled="trouve||fail_response" :id="'res-'+i" v-model="response" class="align-self-center">
										<div class="mot px-2">{{item.phrase}}</div>
									</label></div>
								</div>
							</div>
							<div v-else class="multi">
								<div class="card py-2" style="font-size: 1em!important;background: #fffdd2; color: #0950FF">
								{{form[index_qst].phrase}}
								</div>
								<div class="d-flex">
									<div v-for="(item,i) in tab_prop" :class="" id="liste-propos">
										<label class="ans" :id="'label-'+i" :for="'res-'+i">
											<input type="radio" :value="item.phrase" name="s1" :disabled="trouve||fail_response" :id="'res-'+i" v-model="response" class="align-self-center"/>
											<img  :src="'/public/assets/images/le-verbe/'+item.image+'.png'" class="img val">
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="comment" v-if="empty_response" class="mt-3">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :passed="passed" :submit="submit" :nbre-question="nbre_question" :score="score"  :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/*
*
*questionnaire=[{phrase:"Le policier appelle du renfort.", image:"policier-1",i:0},...] 0<=i<n // i is index of array wich content row on tab array
*
*tab=[[{phrase:"", image:""},{}---{}]] each case is array of n struc:{phrase:"","image:"}
*
*ab=["",""] array of string as label of question: ab[1] is for choosing image and ab[0] is for choosing text
*
*/
	export default{
		data(){
			let data=datas(questionnaire);
			data.img=true;
			data.nbre_question=questionnaire.length;
			return data;
		},
		created(){
			this.initialiser();
			exercice_vue=this;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				if(this.response==""){
					this.empty_response=true;
					this.label=this.ab;
					play(au.empty);
					return 0;
				}
				if(this.response==questionnaire[this.index_qst].phrase){
					this.correct_answer++;
					$("#liste-propos label input:checked + .mot, #liste-propos label input:checked + img").addClass("good").after("<span class='valid'></span>");
					play(au.success);
					this.trouve=true;
				}else{
					play(au.fail);
					if(this.img){
						$("#liste-propos label input:checked +img").addClass("bg-danger");
					}else{
						$("#liste-propos label input:checked + .mot").addClass("barre");
					}
					$("#liste-propos label").eq(this.tab_prop.map(el=>el.phrase).indexOf(questionnaire[this.index_qst].phrase)).find('input').prop("checked",true).next(".mot, img").addClass("good");
					this.fail_response=true;
				}
				make_score(this);
				questionnaire[this.index_qst].pass=true;
				this.answered=questionnaire.filter(el=>el.pass).length;
			},
			initialiser(){
				this.img=rn(0,1);
				this.ab=ab[this.img];
				this.tab_prop=tab[questionnaire[this.index_qst].i].slice().sort_rn();
			},
			next(){
				next_qst(this);
				this.response="";
				$(".valid").remove();
				$("#sujet input").prop("checked",false);
				$(".mot, img").removeClass("barre good bg-danger");
			}
		}
	};
</script>