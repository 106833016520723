<template>
	<div class="row flex-column-reverse flex-md-row" id="les-types-de-phrases-exercice-1">
		<div class="col-md-9 text-center">
			<form class="inner mb-0" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="" class="text-info py-3 text-left" v-if="libelle.length>1">
						<button class="btn btn-sm" @click.prevent="play(0)" type="button">
							<i class="fas fa-volume-up"></i>
						</button>
						<span v-html='libelle' style="font-size:1.3em; color:#333;"></span>
					</div>
					<div class="content-exercice">
						<h3 class="text-info d-block" v-if="typeof(form[index_qst].libelle)=='string'||secondLibelle.lengh>1"> 
							<button class="btn btn-sm" @click.prevent="play(1)" type="button">
								<i class="fas fa-volume-up"></i>
							</button>	
							<span v-html="typeof(form[index_qst].libelle)=='string'?form[index_qst].libelle:secondLibelle"></span>
						</h3>
						<div class="" v-if="typeof(form[index_qst].img)=='string'">
							<img :src="'/public/assets/images/fr/'+form[index_qst].img+'.png'" v-if="typeImage">
							<div v-else class="text-center">
								<h2 class="text-info">
									{{form[index_qst].img}}
								</h2>  
							</div>
						</div>
					</div>
				</div>
				<div class="options-reponse row no-gutters justify-content-center" id="input-field">
					
					<div v-if="input" class="col-md-8 form-group">
						<label class="form-control-label text-primary" for="answer" style="font-size:1.3em">Entre la réponse</label>
						<input :type="typeInput" id="answer" name="response" autocomplete="off" class="form-control text-center" @keydown="inputWatch" v-model='response' autofocus>
					</div>
					<div v-else>
						<div class="d-flex justify-content-around input-field multi align-items-center" v-for="item1 in chunk_array(tab_prop,breakLine)">
							<label v-for="item in item1" class="ans m-1 p-1" :class="{'bg-success':(trouve||fail_response) &&item.value==form[index_qst].answer}">
								<input type="radio" name="s1" :value="item.value" v-model='response' :readonly="trouve||fail_response" :disabled="trouve||fail_response" @checked="beepSon" @change="beepSon">
								<img :src="'/public/assets/images/fr/'+item.src+'.png'" :class="['imgp img-fluid w-100']" style="max-width:140px" :alt="item.value" :title="typeof(item.title)=='string'?item.title:''">
								<div class='croix-rouge d-flex h-100 w-100' v-if="fail_response&&response==item.value">
									<img src='/public/assets/images/croix.png' class='img-fluid my-auto mx-auto'>
								</div>
							</label>
						</div>
					</div>
				</div>
					<div id="comment" v-if="empty_response">{{label}}</div>
					<div class="show-answer text-center my-2 py-2" v-if="input" v-show="fail_response"> 
					    <span class="text-primary">Solution</span><hr class="solid mt-n1">
					  	<div>La bonne réponse est <strong class="text-success" v-html="form[index_qst].answer"></strong></div>
					</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :passed="answered" :correct-answer="correct_answer"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/*
* cette forme d'exercice donne une phrase puis il faut choisir une 'caractéristique' de la phrase
*
*tabProp est le tableau des différentes propositions(qui sont des images): 
*	[{src:'mathemetiques/les-nombres/denombrement/1',value:1},{src:'',value:2},]
*
*@questionnaire est le tableau de réponses sous la forme {answer:x,img:'mathemetiques/les-nombres/denombrement/gateaux-2'} : 
*	image src from 'images/fr'folder


*/
	export default{
		props:{
			libelle:{type:String, required:false, default:"Combien y a-t-il d'éléments?"},
			secondLibelle:{type:String, required:false, default:"Choisis la bonne réponse"},
			input:{type:Boolean, required:false, default:false},
			breakLine:{type:Number, required:false,default:6},
			typeImage:{type:Boolean, required: false, default:true} //Si l'énoncé est une image
		},
		data:()=>{
			let dat=datas(questionnaire);
			dat.nbre_question=questionnaire.length;
			dat.tab_prop=tabProp;
			dat.label="Tu dois choisir une réponse!";
			dat.typeInput='number';
			dat.index_solution=undefined;
			return dat;
		},
		methods:{
			next(){
				next_qst(this);
				this.initialiser();
			},
			restart(){location.reload();},
			initialiser(){
				if(Array.isArray(tabProp[this.index_qst])){
					this.tab_prop=tabProp[this.index_qst].sort_rn();
				}
				if(this.input){
					document.getElementById("answer").focus();
				}
				this.typeInput=typeof(questionnaire[this.index_qst].answer);
			},
			submit(ev){
				ev.preventDefault();
				if(this.nbre_question-this.answered<=0)return 0;
				if(this.response==''){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				questionnaire[this.index_qst].pass=true;
				this.empty_response=false;
				if(this.response==questionnaire[this.index_qst].answer){
					this.correct_answer++;
					this.trouve=true;
					play(au.success);
				}else{
					this.fail_response=true;
					play(au.fail);
				}
				make_score(this);
				$("img").removeClass('bg-success');
			},
			inputWatch(ev){
				console.log(ev);
				if(ev.keyCode==38 || ev.keyCode==40){
					ev.preventDefault();
				}
			},
			play(i){
				if(typeof(questionnaire[this.index_qst].sound)=='number'){
					play(sounds[questionnaire[this.index_qst].sound]);
					return 0;
				}
				if (typeof(sounds)=='object') {
					play(sounds[i]);
				}
			},
			chunk_array(arr,size){
				let arr2=arr.chunk(size);
				return arr2;
			}
		},
		watch:{
			tab_prop(n,m){
			}
		},
		mounted(){
			this.initialiser();
		},
		created(){
			exercice_vue=this;
		}
	};
</script>