<template>
	<div>
		<div class="options-reponse p-4">
			<submit-section :fail="fail" :trouve="trouve" :end="end" :submit="submit" :score="score" :nbre-question="nbreQuestion" :next="next"></submit-section>
		</div>
		<end-section :score="score" :end="end"></end-section>
		<navigation-game :passed="passed" :nbreQuestion="nbreQuestion"></navigation-game>
	</div>
</template>
<script>
	export default{
		props:{
			fail:{required:true, type:Boolean},
			trouve:{required:true, type:Boolean},
			end:{required:true, type:Boolean},
			submit:{required:true, type:Function},
			next:{required:false, type:Function},
			score:{required:true, type:Number},
			passed:{required:true, type:Number},
			nbreQuestion:{required:false, default:1, type:Number},
		},
		data(){
			return {matiere:matiere}
		}
	};
</script>