<template>
	<div>
		<div class="py-3 px-2 text-left" style="text-align-last:initial" id="choose-good-text-for-text">
			<div class="main-quiz-holder">
				<div class="slide-container d-block py-3">
					<div class="py-3">
						<div class="d-flex justify-content-around" v-if="reponseFausse||reponseVraie">
							<div class="correct" v-show="reponseVraie"><span>Correct</span></div>
							<head-exercise :afficher-reponse="afficheReponse" :reponse-vraie="reponseVraie" :reponse-fausse="reponseFausse" :rejouer="rejouer"></head-exercise>
							<div>
								<button class="btn question-number" @click="rejouer">Rejouer</button>
							</div>
						</div>

						<div class="question my-4">
							<div class="featureText1 ml-5" v-html="question"></div>
							<hr class="mt-4 mb-2">
						</div>
						<div v-if="input">
							<div class="d-flex justify-content-center" id="question">
								<input type="text" name="" v-model="reponse" id="input-reponse" :readonly="repondu" :disabled="repondu" class="form-control col-md-4 text-center" autofocus @keydown="controlInput">
								<div class="result-container notice col-md-5" v-show='reponseVide'>Entre une réponse valide!</div>
							</div>
						</div>
						<div v-else>
							<ul class="answers multi" id="answers">
								<li v-for="(item,i) in propositions" :class="['mx-1 p-0',{selected:reponse==item}]">
									<label :for="'input-'+i" @select="beepSon" :class="{answered:repondu,'bad-answer':reponseFausse&&reponse==item, 'good-answer':repondu&&bonneReponse==item}">
										<input type="radio" :id="'input-'+i" name="s1" v-model="reponse" :value="item" :readonly="repondu" :disabled="repondu">
										<div :class="[!repondu?'proposition':'border text-dark', 'px-3 py-2 mx-0']">{{item}}</div>
									</label>
								</li>
							</ul>
							<div class="result-container notice" v-show='reponseVide'>choisis une réponse</div>
						</div>
						<div style="font-size:1.2em">
							<p style="color:#999;"><em v-html="notice"></em></p>
							<p class="my-3" v-show="afficherReponse">
								La bonne réponse est : <span class="text-success" v-html="bonneReponse"></span>
							</p>
						</div>
						<div class="nav-container">
							<button class="btn next final" @click="soumettre" type="button">Valider </button>
						</div>
						
					</div>
					<navigation-frame :question-suivante="questionSuivante"></navigation-frame>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/*
*
*questionnaire:[{libelle:'',solution:Xn, propositions:[x1,x2,---]}]
*
*
*
*
*
*/
	export default{
		props:{
			input:{type:Boolean, required:false, default:false},
			notice:{type:String, required:false, default:''}
		},
		data:function () {
			return {
				question:'',
				propositions:[],
				reponse:'',
				reponseVide:false,
				reponseFausse:false,
				reponseVraie:false,
				bonneReponse:0,
				repondu:false,
				index:0,
				afficherReponse:false,
			}
		},
		created(){
			this.initialiser();
		},
		methods:{
			questionSuivante(e){
				this.rejouer();
				this.index=e==1? (this.index+1) % questionnaire.length: 
					e==-1? Math.max(this.index-1,0):
					rn(0,questionnaire.length-1) ;	
				this.initialiser();
			},
			initialiser(){
				// let i=rn(0,questionnaire.length-1);
				let i=this.index;
				if(!this.input){
					this.propositions=questionnaire[i].propositions.sort_rn();
				}else{
					this.typeInput=typeof(questionnaire[this.index].typeInput)=='string'?questionnaire[this.index].typeInput:'number';
				}
				this.bonneReponse=questionnaire[i].solution;
				this.question=questionnaire[i].libelle;
			},
			rejouer(){
				this.reponse='';
				this.reponseFausse=false;
				this.reponseVraie=false;
				this.reponseVide=false;
				this.repondu=false;
				this.afficherReponse=false;
				// document.getElementById('input-reponse').focus();
			},
			soumettre(ev){
				if(this.reponse==''){
					this.reponseVide=true;
					play(au.empty);
					return 0;
				}
				this.repondu=true;
				if(this.reponse==this.bonneReponse){
					this.reponseVraie=true;
					play(au.success);
				}else{
					this.reponseFausse=true;
					play(au.fail);
				}
			},
			controlInput(ev){
				console.log(ev);
				if(Array.isArray(questionnaire[this.index].typeInput)){
					if(!questionnaire[this.index].typeInput.includes(ev.key) && ev.keyCode!=8){
						console.log(ev.key);
						ev.preventDefault();
					}
				}else{
					console.log(ev.keyCode);
					if((ev.keyCode<96 || ev.keyCode>105 ) && ev.keyCode!=8){
						console.log('not digit');
						ev.preventDefault();
					}
				}
			},
			afficheReponse(){
				this.afficherReponse=true;
			}
		},
		watch:{
		}
	};
</script>