<template>
	<div class="ptrn02" id="multiplie-quiz">
		<h2 class="duoline ml-0 my-3">Exercice – Table de multiplication : Multiplie par {{facteursTab}}</h2>
		<div class="row no-gutters">
			<div class="col-md-3">
				<google-auto-ad></google-auto-ad>
			</div>
			<div class="col-md-9">
				<div class="box py-3 px-2 text-left" style="text-align-last:initial">
					<div class="main-quiz-holder">
						<div class="slide-container d-block">
							<div>
								<div class="d-flex justify-content-around" v-if="reponseFausse||reponseVraie">
									<div class="correct" v-show="reponseVraie"><span>Correct</span></div>
									<div v-show="reponseFausse">
										<div class="wrong">
											<span>Incorrect</span>
										</div>
									</div>
									<div>
										<button class="btn question-number" @click="rejouer">Rejouer</button>
									</div>
								</div>

								<div class="question">
									<div class="featureText1">Multiplie: </div>
									<div class="featureQuestion">{{operation}} =</div>
									<div class="featureText2">Choisis la bonne réponse.</div>
								</div>
								<ul class="answers multi" id="answers">
									<li v-for="item in propositions" class="mx-1">
										<label :for="'input-'+item" @select="beepSon" :class="{answered:repondu,'bad-answer':reponseFausse&&reponse==item, 'good-answer':repondu&&bonneReponse==item}">
											<input type="radio" :id="'input-'+item" name="s1" v-model="reponse" :value="item" :readonly="repondu" :disabled="repondu">
											<div :class="[!repondu?'proposition':'border text-dark', 'px-3 mx-0']">{{item}}</div>
										</label>
									</li>
								</ul>
								<div class="nav-container">
									<button class="btn next final" @click="soumettre">
										<i class="fa fa-check valid-color"></i>Valider
									</button>
								</div>
								<div class="result-container notice" v-show='reponseVide'>choisis une réponse</div>
							</div>

							<div class="mt-3">
								<button class="btn btn-lg btnext flex-fill" @click="questionSuivante">Suivant >></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template> 

<style scoped>
	/*@import '/public/assets/css/styles-multiplication.css'*/
</style>
<script>



/*
*
*here libelle is prop.
*questonnaire=[{}]
*
*/
	export default{
		props:{
			facteursTab:{type:String, required:true, default:'2'}
		},
		data(){
			let data={
				facteurs:this.facteursTab.split('-').map(el=>parseInt(el)),
				operation:'',
				propositions:[],
				reponse:0,
				reponseVide:false,
				reponseFausse:false,
				reponseVraie:false,
				bonneReponse:0,
				repondu:false
			};
			return data;
		},
		created(){
			this.initialiser();
		},
		methods:{
			questionSuivante(){
				this.rejouer();
				this.initialiser();
			},
			initialiser(){
				let tab=[1,2,3,4,5,6,7,8,9,10,11,12].sort_rn(), fact2=tab[0], fact1=this.facteurs.sort_rn()[0];
				this.operation=fact1+'X'+fact2;
				this.bonneReponse=fact1*fact2;
				this.propositions=[this.bonneReponse,this.bonneReponse-fact2,this.bonneReponse+fact2].sort_rn();
			},
			rejouer(){
				this.reponse=0;
				this.reponseFausse=false;
				this.reponseVraie=false;
				this.reponseVide=false;
				this.repondu=false;
			},
			soumettre(ev){
				if(this.reponse==0){
					this.reponseVide=true;
					play(au.empty);
					return 0;
				}
				this.repondu=true;
				if(this.reponse==this.bonneReponse){
					this.reponseVraie=true;
					play(au.success);
				}else{
					this.reponseFausse=true;
					play(au.fail);
				}
			},
		},
		watch:{
		}
	};
</script>