<template>
	<div :class="listStyleType">
		<div v-html="title"></div>
		<ul :class="[column]">
			<li v-for="item in donnees">
				<a :href="'/'+item.link" target="_blank" class="tooltips" rel="noopener noreferrer" >
					<span v-html="item.title"></span>
					<span class="tooltiptext" v-if="typeof(item.src)!='undefined'&&item	.src.length>0">
						<img :src="'/public/assets/'+item.src+'.png'" :alt="item.alt">
					</span>
					<div v-else>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>
<script>
	export default{
		props:{
			datas:{type:String, required:true},
			listStyleType:{type:String, required:false, default:'mylist0'},
			title:{type:String, required:false, default:''},
			column:{type:String, required:false, default:''}
		},
		data(){
			return {donnees:JSON.parse(this.datas)}
		}
	};
</script>