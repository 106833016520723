<template>
	<div class="row flex-column-reverse flex-md-row" id="select-good-proposition-block">
		<div class="">
			<form class="inner" @submit.prevent="submit" id="game">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="row content-exercice">
						<div class="col-2 img-fluid px-0 d-flex">
							<img :src="'/public/assets/enfants/'+ab+'.png'" class="img w-100 my-auto mx-auto">
						</div>
						<div class="col-lg text-left" id="liste-prop" style="font-size: 1.3em">
							<div v-for="(item, index) in tab_prop">
								<label :for="'res-'+index" :id="'label-'+index" class="ans d-inline-block">
									<input type="radio" :value="item.replace('<strong>','').replace('</strong>','')" name="s1" :disabled="trouve||fail_response||answered>=nbre_question" :id="'res-'+index" v-model="response"/>
									<div class="mot" v-html="item"></div>
								</label>
							</div>
						</div>
					</div>
					<div id="comment" v-show="empty_response">{{label}}.</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :trouve="trouve" :end="end" :passed="answered" :matiere='matiere'></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*libelle=""
*
*questionnaire=[["","",---,""],[],---,["","",---""]] when on each subArray, the good answer is the first string
*
*/
	export default{
		data(){
			let dat=datas(questionnaire);
			dat.libelle=libelle;
			dat.matiere=matiere;
			dat.nbre_question=questionnaire.length;
			return dat;
		},
		methods:{
			next(){
				next_qst(this)
				$("label").removeClass('valid').find(".mot").removeClass('barre cadre');
			},
			initialiser(){
				var index=this.index_qst;
				this.tab_prop=questionnaire[index].props.slice();
				this.tab_prop.sort_rn();
				this.index_solution=this.tab_prop.indexOf(questionnaire[index].props[0]);
				this.ab=rn(1,73);
				this.good_answer=questionnaire[index].props[0].replace('<strong>','').replace('</strong>','');
			},
			submit(ev){
				ev.preventDefault();
				if(!this.response){
					this.empty_response=true;
					this.label="Choisis une réponse!";
					play(au.empty);
					return 0;
				}
				submit(this,questionnaire);
				if(this.fail_response){
					$("#liste-prop label input:checked +.mot").addClass('barre');
				}else if(this.trouve){
					$("#liste-prop label").eq(this.index_solution).addClass('valid');
				}
				$("#liste-prop label").eq(this.index_solution).find('.mot').addClass('cadre');
			},
		},
		watch:{
		},
		created(){
			this.initialiser();
			exercice_vue=this;
		},};
</script>