<template>
	<div class="row flex-column-reverse flex-md-row" id="order-words-in-cells">
		<div class="col-md-9 text-center">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<table class="table table-bordered border-perso">
						<thead>
							<tr>
								<th ondragover="alowDrop(event)" ondrop="remove_copy(event)" class="text-danger border-danger w-25
								">Supprimer</th>
								<th v-for="cel in tab_cels" class="table-info" :style="{width:75/tab_cels.length+'%'}">{{cel}}</th>
							</tr>
						</thead>
						<tbody id="body-reponse">
							<tr v-for="i in form[index_qst].reponse.length" :id="'tr-'+i">
								<td ondragover="alowDrop(event)" ondrop="remove_copy(event)" :rowspan="form[index_qst].reponse.length" v-if="i==1"><i class="fa fa-trash text-danger"></i></td>
								<td v-for="cel in tab_cels.map(el=>get_id(el))" :id="'reponse-'+cel+i" @dragover.self="alowDrop" @drop.prevent.self="drop" :class="['reponse', cel]"></td>
							</tr>
						</tbody>
					</table>
					<div id="phrase" class="d-flex justify-content-center input-field mt-4 pb-4" style="font-size: 1em;">
						<label :id="'mot-'+i" v-for="(item,i) in tab_prop" :class="['label px-0', classWords(item.mot,'ans label')]" :draggable="!item.use&&!(trouve||fail_response)&&!ponctuation.includes(item.mot)" ondragstart="drag(event)" :for="'input-'+i"><input type="text" :value="item.mot"><div class="mot">{{item.mot}}</div></label></div>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :nbre-question="nbre_question" :score="score"  :next="next" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire=[{phrase:"", reponse:[{cel1:"n/m/p/---"},{cel2:"n2/m2/p2/---"},---]
*
*Separate answers with '/'
*
*order:boolean.
*
*
*/
	export default{
		props:{
			libelle:{required:false,default:"Lis attentivement et glisse chaque mot dans sa case correspondante."},
			order:{type:Boolean, default:true, required:false}
		},
		data(){
			let data=datas(questionnaire);
			data.tab_cels=tab_cels;
			data.nbre_question=questionnaire.length;
			return data;
		},
		created(){
			this.initialiser();
			exercice_vue=this;
		},
		methods:{
			next(){
				next_drag(this);
			},
			initialiser(){
				let phrase=questionnaire[this.index_qst].phrase;
				this.tab_prop=phrase.split(" ").map(el=>{return {mot:el,use:false}});
			},
			drop(ev){
				copy(ev,true);
				this.answered=questionnaire.filter(el=>el.pass).length;
			},
			submit(ev){
				ev.preventDefault();
				if($("#body-reponse tr .reponse label.ans").length<1){
					this.empty_response=true;
					this.label="Tu dois renseigner au moins une combinaison.";
					play(au.empty);
					return 0;
				}

				let reponse=questionnaire[this.index_qst].reponse,cel, tb_id=tab_cels.map(el=>get_id_cel(el));
				submited=$("#body-reponse tr").map(function(index, elem) {
					let p=tab_cels.reduce((p,e,i)=>p&&$(elem).find('.'+tab_cels[i]+" input"), true), obj={};
					for(cel of tb_id){
						obj[cel]=$(elem).find('.'+cel+" input").map((i,e)=>$(e).val()).get();
					}
					return obj;
				}).get();
				let checked=[],mystere=[], m=1, n=0, bool={};

				if(!this.order){
					for(cel of tb_id){
						m=reponse.map((el,ind)=>el[cel].length+1).reduce((a,b)=>a+b);
					}
				}
				for(let i=0; i<submited.length; i++){
					let b_nom=false,b_adjec=false, repere;
					for(cel of tb_id){
						repere=reponse.map((el,j)=>{return {mot:el[cel],ind:j}}).filter(el=>el.mot.includes(submited[i][cel]))[0];
						if(typeof repere!="undefined"){
							break;
						}
					}
					if(typeof repere!='object'){
						mystere.push(i);
						$("#body-reponse tr").eq(i).find('label.ans .mot').addClass('barre');
					}else{
						checked.push(repere.ind);
						for(cel of tb_id){
							bool[cel]=check_(submited[i][cel],reponse[repere.ind][cel].split('/'),this.order);
							if(!bool[cel][0]){
								if(!this.order){
									let r=reponse[repere.ind][cel].split('/');
									for(let k=0;k<r.length;k++){
										if(!submited[i][cel].includes(r[k])){
											this.add_answer(r[k],cel,i);
											this.fail_response=true;
											$("#body-reponse tr").eq(i).find("."+cel+" label .mot").eq(bool[cel][1][k]).addClass('barre');
										}else{
											n+=Math.round(1/m);
										}
									}
								}else{
									$("#body-reponse tr").eq(i).find('.'+cel+' .mot').addClass('barre');
									this.add_answer(reponse[repere.ind][cel],cel,i);
								}
							}else{
								;
								$('#body-reponse tr').eq(i).find('.'+cel+' label').last().after('<span class="valid"><span>');
								n++;
							}
						}
					}
				}
				let rep=reponse.filter((el,i)=>!checked.includes(i));
				mystere.forEach((mys,j)=> {
					for(cel of tb_id){
						this.add_answer(rep[j][cel],cel,mys);
					}
					rep[j].checked=true;

				});
				this.correct_answer=n/reponse.length;
				questionnaire[this.index_qst].pass=true;
				this.fail_response=mystere.length>0?false:true;
				for(cel in bool){
					this.fail_response=this.fail_response&&bool[cel][0];
				}
				this.fail_response=!this.fail_response;
				if(this.fail_response){
					play(au.fail);
				}else {
					play(au.success);
					this.trouve=true;
				}
				make_score(this);
				if(!this.empty_response){
				}
			},
			get_last(txt){return txt.get_last();},
			add_answer(txt,cel,i){
				$("#body-reponse tr").eq(i).find('.'+cel+' label').last().after("<label class='copy m-1 p-1'><div class='mot cadre'>"+txt.split('/')+"</div></label>");
			},
			get_id(txt){
				return get_id_cel(txt);
			}
		},
		watch:{}
	};
</script>