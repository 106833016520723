<template>
	<div class="row flex-column-reverse flex-md-row" id="classify-words">
		<div class="col-md-9">
			<form class="inner multi not-solution" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<table class="table table-bordered border-perso text-left">
						<thead>
							<tr class="text-center">
								<th class="w-25 border-danger" @dragover="alowDrop" @drop="remove_copy"><i class="fa fa-trash text-danger"></i></th>
								<th class="table-info px-0 th-cel" v-for="cel in tab_cels" :style="{width:75/tab_cels.length+'%'}">{{cel}}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="px-0">
									<div class="d-flex no-gutters flex-wrap" id="cel-propos">			
										<label class="nom ans border p-0  m-1" :id="'lbl-'+i" v-for="(item,i) in tab_prop" :draggable="!item.use&&!(trouve||fail_response)" ondragstart="drag(event)">
											<input type="text" name="cel" :value="item.mot" :id="'input-'+i">
											<div :class="['mot',{barre:item.use},'px-1']">{{item.mot}}</div>
										</label>
									</div>
								</td>
								<td v-for="cel in tab_cels" :class="['border tableau-cel reponse px-0',get_id_cel(cel)]" @drop.self="drop" @dragover.self="alowDrop" :id="'reponse-'+get_id_cel(cel)"></td>
							</tr>
						</tbody>
					</table>
					<div id="comment" v-if="empty_response">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*reponse:{cel1:["xxx nnn"],cel2:[]}
*
*tab_cels:["",""]
*
*
*
*/
	export default{
		props:{libelle:{default:"Glisse chaque élément dans la ase correspondante.", required:false, type:String}},
		data(){
			let data=datas([""]);
			data.tab_cels=tab_cels;
			return data;
		},
		created(){
			exercice_vue=this;
			this.tab_prop=tab_cels.map(el=>reponse[get_id_cel(el)]).flat().map(el=>{return {mot:el};}).sort_rn();
		},
		methods:{
			drop(ev){
				copy(ev,true);
				;
			},
			submit(ev){
				ev.preventDefault();
				let l, cel;
				this.empty_response=false;
				if($(".reponse label.ans").length<this.tab_prop.length){
					this.label="Tu dois ranger tous les mots de la liste";
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				$(".reponse").append('<hr class="dashed">');
				for(cel of tab_cels){
					$("#reponse-"+get_id_cel(cel)+" label.ans input").each(function(index, el) {
						if(reponse[get_id_cel(cel)].includes($(el).val())){
							exercice_vue.correct_answer++;
							$(el).siblings('.mot').addClass('cadre').after('<span class="valid"></span>');
						}else{
							let c,c2;
							for(c of tab_cels){
								if(reponse[get_id_cel(c)].includes($(el).val())){
									c2=c;
									break;
								}
							}
							$("#reponse-"+get_id_cel(c2)).append("<label class='copy mx-1 cel p-0'><div class='mot cadre'>"+$(el).val()+"</div></label>");
							exercice_vue.fail_response=true;
						}
					});
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
			get_id_cel(cel){
				return get_id_cel(cel);
			},
		},
		watch:{}
	};
</script>