<template>
	<div class="row flex-column-reverse flex-md-row" id="les-types-de-phrases-exercice-1">
		<div class="col-md-9 text-center">
			<form class="inner not-solution" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="libelle" v-html='libelle'></div>
					<div class="content-exercice">
						<div id="phrase" style="font-size: 1.2em!important;color:#0f69d6;">{{form[index_qst].phrase}}<hr class="dashed"></div>

						<div class="row text-left no-gutters mt-4" id="liste-prop">
							<div class="col-6 mx-0" v-for="(item, i) in tab_prop">
								<label :for="'res-'+i" :id="'label-'+i" class="ans mb-0">
									<input type="radio" :value="item" name="s1" :disabled="trouve||fail_response" :id="'res-'+i" v-model="response" @change="selected='res-'+i" />
									<div class="mot">{{item}}</div>
								</label>
							</div>
						</div>
					</div>
					<div id="comment" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :passed="answered" :correct-answer="correct_answer"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/*
* cette forme d'exercice donne une phrase puis il faut choisir une 'caractéristique' de la phrase
*
*@tab_prop est le tableau de propositions contenant les differentes occurences de 'caractéristique'
*@questionnaire est le tableau de phrases sous la forme {phrase:"", solution:x} avec x l'indice de reponse dans tab_prop(à partir de 0)


*/
	export default{
		props:{libelle:{type:String, required:false, default:"Lis attentivement puis sélectionne la réponse qui convient."}},
		data:()=>{
			let dat=datas(questionnaire);
			dat.tab_prop=tab_prop;
			return dat;
		},
		methods:{
			next(){
				next_qst(this)
				$("label.ans").removeClass("valid fail").find('.mot').removeClass('barre cadre');
			},
			initialiser(){
				this.ab=rn(1,72);
				this.good_answer=this.tab_prop[questionnaire[this.index_qst].solution]
			},
			select(ev){
				this.selected=ev.target.id;
			},
			submit(ev){
				ev.preventDefault();
				if(!this.response){
					this.empty_response=true;
					this.label="Choisis une réponse!";
					play(au.empty);
					return 0;
				}
				submit(this,questionnaire);
				if(this.fail_response){
					$("#liste-prop label input:checked +.mot").addClass('barre');
				}else if(this.trouve){
					$("#liste-prop label").eq(questionnaire[this.index_qst].solution).addClass('valid');
				}
				$("#liste-prop label").eq(questionnaire[this.index_qst].solution).find('.mot').addClass('cadre')
			},
			restart(){
				location.reload();
			}
		},
		watch:{
			tab_prop(n,m){
			}
		},
		created(){
			this.initialiser();
		}};
</script>