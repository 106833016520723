<template>
	<div class="row flex-column-reverse flex-md-row">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner multi" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="content-exercice" style="font-size: 1em">
						<table class="table table-bordered border-perso">
							<thead>
								<tr class="text-center">
									<th class="text-danger border-danger w-25" ondrop="remove_copy(event)" @dragover="alowDrop" id="div-rest"><i class="fa fa-trash"></i></th>
									<th class="table-info">Phrases</th>
								</tr>
							</thead>
							<tbody id="phrases">
								<tr v-for="(item,i) in form" :key="item.phrase">
									<td id="propos" :rowspan="form.length" v-if="i==0" class="px-0 border-perso text-center maj align-middle">
										<div class="d-flex flex-wrap flex-column">
											<label :id="'lbl-'+j" class="label ans nom border m-1 px-2" v-for="(it,j) in tab_prop" :draggable="!(trouve||fail_response)" ondragstart="drag(event)">
												<input type="text" :value="it.mot" class="val">
												<div class="mot" draggable="false">{{it.express?it.express:it.mot}}</div>
											</label>
										</div>
									</td>
									<td class="px-1 text-left phrase py-1 align-middle maj">
										<span v-for="k in item.part.length-1">
											<span v-html="item.part[k-1]"></span><div :class="['reponse drop d-inline-block',{maj:(k-1==0&&item.part[k-1]=='')}]" :id="'reponse-'+i+'-'+k" @dragover.self="alowDrop" ondrop="copy(event)"></div>
										</span><span v-html="item.part.slice(-1).toString()"></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div id="noms" class="row input-field mt-3">
					</div>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :matiere="matiere" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		data(){
			let d=datas(questionnaire);
			d.index_exo=index_exo;
			d.matiere=matiere;
			d.tab_prop=tab_prop;
			d.libelle=libelle;
			return d;
		},
		created(){
			exercice_vue=this;

		},
		props:{
			nbreQuestion:{required:false, default:1,type:Number}
		},
		methods:{
			next(){},
			submit(ev){
				ev.preventDefault();
				if($("#phrases .phrase .reponse").not(":has('label.ans input')").addClass("border-warning").length>0){
					this.empty_response=true;
					play(au.empty);
					this.label="Tu dois remplir toutes les cases vides."
					return 0;
				}
				this.empty_response=false;
				let submited=$("#phrases .phrase").map((el,ind)=>$(ind).find("input").map((e,i)=>$(i).val()).get()).get(), reponses=questionnaire.map(el=>el.solution).flat();
				$("#phrases .temporaly").remove();
				for(let i=0;i<submited.length;i++){
					let txt=(Array.isArray(reponses[i])?reponses[i]:reponses[i].split("/")).map(el=>el.toLowerCase());
					if(txt.includes(submited[i].toLowerCase())){
						this.correct_answer++;
						$("#phrases .phrase .drop").eq(i).find("label.ans .mot").addClass('text-orange').after("<span class='valid'></span>");
					}else{
						txt=Array.isArray(reponses[i])?reponses[i].join("/"):reponses[i];
						$("#phrases .phrase .drop").eq(i).find("label.ans .mot").addClass("barre").before("<strong class='text-success mr-1'>"+txt+"</strong>");
						this.fail_response=true;
					}
					$("#phrases .phrase .drop").eq(i).addClass("bg-white border-white").find("label.ans").removeClass('ans');
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					play(au.success);
					this.trouve=true;
				}
				make_score(this);
			}
		},
	};
</script>