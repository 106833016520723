<template>
	<div class="row flex-column-reverse flex-md-row" id="complete-text-drag-drop-table">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="" >
						<h3 v-if="typeof(form[index_qst].libelle)=='string'">{{form[index_qst].libelle}}</h3>
						<div id="corps" class="d-flex" style="height:220px">
							<div class="bg-grey col-6 p-2">
								<div class="bg-light border-white shadow h-100 w-100 m-auto">
									<div class="row align-items-start h-100 pt-1 no-gutters justify-content-around" id="answer" @dragover.self="alowDrop" @drop="move">
										<label v-for="i in ab" :id="'lbl-'+i" class="label ans p-0 m-0" ondragstart="drag(event)" :draggable="!(trouve||fail_response)">
											<img :src="'/public/assets/images/fr/'+img+'.png'" style="max-height:72px" draggable="false">
										</label> 
									</div>
								</div>
							</div>
							<div v-if="typeof(form[index_qst].img)=='string'">
								<img :src="'/public/assets/images/fr/'+form[index_qst].img+'.png'">
							</div>
							<div id="number" class="col d-flex flex-column justify-content-center" v-else>
								<div class="bg-dark rounded text-white text-center align-self-end shadow h-25 w-50">
									<div class="m-auto" style="font-size:2.4em">{{form[index_qst].answer}}</div>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-between mt-5">
							<div id="propos" @dragover.self="alowDrop" @drop.self="move" class="d-flex no-gutters align-items-start border w-100 pr-3 py-2" style="min-height:50px">
								<label v-for="i in max-ab" class="label ans m-0 p-0" ondragstart="drag(event)" :id="'lbl-'+(ab+i)" :draggable="!(trouve||fail_response)">
									<img :src="'/public/assets/images/fr/'+img+'.png'" draggable="false" :alt="'complète les collections aliments pour enfant '+img" style="max-height:72px">
								</label> 
							</div>
						</div>
					</div>

					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire=[{answer:0, img:''?}]
*
*/
	export default{
		props:{
			libelle:{required:false, default:"Complète la collection pour qu'elle corresponde au nombre associè.", type:String},
			max:{type:Number, required:true},
			min:{type:Number, required:false, default:1},
			zero:{type:Boolean, required:false, default:false},// define if on begiing there is no element on the result box 
			reduce:{type:Boolean, required:false, default:false}, //define it is reduction or completion
			comp:{type:Number, required:false, default:0}, // if =0 equal, <0 less than, >0 more than
		},
		data(){
			let data=datas(questionnaire);
			data.nbre_question=questionnaire.length;
			data.label="Tu dois ajouter ou diminuer des éléments dans la case.";
			return data;
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		mounted(){
		},
		methods:{
			next(){
				$("label.copy").remove();
				$("#answer label").removeClass('bg-warning');
				next_qst(this);
			},
			initialiser(){
				if (this.zero) {
					this.ab=0;
				}else{
					this.ab=this.reduce?rn(questionnaire[this.index_qst].answer,this.max+2):rn(this.min,questionnaire[this.index_qst].answer-1);
				}
				if(typeof(questionnaire[this.index_qst].compare)=='number'){
					this.compare=questionnaire[this.index_qst].compare;
				}else{this.compare=this.comp;}
				$("label").removeClass('opacity-5');
				$("label.ans").detach().appendTo('#propos');
				this.img='jeux/aliments/'+rn(1,16);
			},
			submit(ev){
				ev.preventDefault();
				let submited=[];
				this.empty_response=false;
				if($("#answer label").length==this.ab && $("#answer label").length!=questionnaire[this.index_qst].answer){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				if(
					this.compare==0 && $("#answer label").length!=questionnaire[this.index_qst].answer ||
					this.compare>0 && $("#answer label").length<=questionnaire[this.index_qst].answer ||
					this.compare<0 && $("#answer label").length>=questionnaire[this.index_qst].answer
				){
					this.fail_response=true;
					play(au.fail);
					for(let i=$("#answer label").length; 
						this.compare==0&& i<questionnaire[this.index_qst].answer || this.compare>0 && i<questionnaire[this.index_qst].answer+rn(1,3); 
						i++
					){
						let clone=$("#lbl-1").clone();
						clone.attr('id',"lbl-1-"+i).addClass('bg-info p-1 copy').appendTo("#answer");
					}
					for(let i=questionnaire[this.index_qst].answer; 
						this.compare==0 && i<$("#answer label").length || this.compare<0 && i<Math.max($("#answer label").length-rn(1,2),1);
						i++
					){
						$("#answer label").eq(i-1).addClass('p-1 bg-warning');
						;
					}
				}else{
					this.trouve=true;
					play(au.success);
					this.correct_answer++;
				}
				$("#propos label").addClass('opacity-5');
				make_score(this);
			},
			move(ev){
				let idElt=ev.dataTransfer.getData('text/plain');
				$("#"+idElt).detach().appendTo(ev.target)/*.moveAt(ev.pageX,ev.pageY)*/;	
			}
		},
		watch:{}
	};
</script>