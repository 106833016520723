<template>
	<div class="row flex-column-reverse flex-md-row" id="">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="content-exercice" style="font-size: 1.2em">
						<table class="table table-bordered border-perso">
							<thead>
								<tr class="text-center">
									<th class="text-danger border-danger" ondrop="remove_copy(event)" @dragover="alowDrop" id="div-rest"><i class="fa fa-trash"></i></th>
									<th class="table-info">{{ab}}</th>
									<th v-show="fail_response" class="table-info">Réponses</th>
								</tr>
							</thead>
							<tbody id="phrases">
								<tr v-for="(item,i) in form" :key="item.phrase">
									<td id="propos" :rowspan="form.length" v-if="i==0" class="border-perso text-center">
										<div class="d-flex flex-column justify-content-around">
											<label :id="'lbl-'+j" class="label ans nom border p-0 d-block" v-for="(it,j) in tab_prop" :draggable="!it.use&&!(trouve||fail_response)" ondragstart="drag(event)">
												<input type="text" :value="it.mot" class="val">
												<div :class="['mot',{barre:it.use}]" draggable="false">{{it.mot}}</div>
											</label>
										</div>
									</td>
									<td class="pl-5 text-left py-1 phrase">
										{{item}}<div class="reponse d-inline-block drop" :id="'reponse-'+i" @dragover.self="alowDrop" ondrop="copy(event)"></div>
									</td>
									<td v-show="fail_response">{{item}}<span class="text-success">{{tab_reponses[i].term}}</span></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div id="noms" class="row input-field mt-3">
					</div>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
					</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :matiere="matiere" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct_answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		data(){
			let data=datas(questionnaire);
			data.ab=ab;
			data.libelle=libelle;
			data.tab_reponses=tab_reponses;
			data.matiere=matiere;
			return data;
		},
		created(){
			this.tab_prop=tab_reponses.map(el=>{return {mot:el.term}}).sort_rn();
			exercice_vue=this;
		},
		methods:{
			submit(){
				if($("#phrases .phrase").not(":has(label.ans)").addClass("border-warning").length>0){
					this.empty_response=true;
					play(au.empty);
					this.label="Tu dois renseigner la terminaison de tous le spersonnages";
					return 0;
				}
				$("#phrases label").attr("draggable",false);
				submited=$("#phrases .phrase .drop input").map((el,ind)=>$(ind).val()).get();
				for(let i=0;i<submited.length;i++){
					if(submited[i]==tab_reponses[i].term){
						this.correct_answer++;
						$("#phrases .phrase").eq(i).find(".drop").after("<span class='valid'></span>");
					}else{
						this.fail_response=true;
						$("#phrases .phrase").eq(i).find("label.ans .mot").addClass("barre");
					}
					$("#phrases .phrase").eq(i).find('.drop').addClass("border-0 bg-white");
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			}
		}
	};
</script>