<template>
	<div class="row flex-column-reverse flex-md-row" id="complete-text-drag-drop-table">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<table class="table table-bordered border-perso">
						<thead>
							<tr class="text-center">
								<th class="text-danger border-danger" ondrop="remove_copy(event)" ondragover="alowDrop(event)" id="div-rest" style="width: 35%" v-if="!input"><i class="fa fa-trash"></i></th>
								<th class="table-info">{{ab}}</th>
							</tr>
						</thead>
						<tbody><!--  -->
							<tr v-for="(item,i) in texte" :key="item.phrase">
								<td v-if="!input&&i==0" :rowspan="texte.length" class="align-middle">
									<div class="d-flex flex-column justify-content-center flex-wrap text-center" id="words-to-drag">
										<label :id="'lbl'+j" class="col-auto label ans border m-1" v-for="(it,j) in tab_prop" :draggable="(!it.use||it.use&&!move)&&!(trouve||fail_response)" ondragstart="drag(event)">
											<input type="text" name="" :id="'input-'+j" :value="it.mot">
											<div :class="['mot',{barre:it.use&&move}]" :id="'word-'+j" draggable="false">{{it.mot}}</div>
										</label>
									</div>
								</td>
								<td>
									<div class="text-left pl-2 text-justify" id="texte" v-html="item">
									</div>
								</td>
							</tr>
								</td>
							</tr>
						</tbody>
					</table>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*tab_prop:[""] list of words wich we can remove from text
*
*text:""
*propositons:undefined
*
*
*/
	export default{
		props:{
			libelle:{required:false, default:"Complète le texte avec les termes convenant.", type:String},
			move:{type:Boolean, dafault:true,required:false},
			terminaison:{type:Boolean, default:false, required:false},
			input:{type:Boolean, default:false, required:false},
			answerBefore:{type:Boolean,required:false, default:false}
		},
		data(){
			let q=typeof texts=='object'?texts.map(el=>{return {m:el}}):[""];
			let data=datas(q);
			data.nbre_question=q.length;
			data.ab=typeof ab=='undefined'?"Texte":ab;
			return data;
		},
		mounted(){
			if(this.input){
				$("#texte input").eq(0).focus();
			}
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		mounted(){
			$(".reponse").on("dragover",alowDrop);
			$(".reponse label").on("draginit dragstart dragover dragend drag drop",function(e){
				e.preventDefault();
				e.stopPropagation();
			});
			let reponse_tab=document.getElementsByClassName('reponse');
			for(let i=0;i<reponse_tab.length;i++){
				reponse_tab[i].addEventListener("drop",copy);
			}
		},
		methods:{
			next(){
				next_drag(this);
			},
			initialiser(){
				if(typeof texts=="object"){
					this.ab=ab[this.index_qst];
					texte=texts[this.index_qst];
					tab_mots=tabMots[this.index_qst];
				}
				if(this.terminaison){
					this.good_answer=this.input?
						tab_mots.map((el,i)=>{
							texte=texte.replace(el.mot,"<div class='mot input' id='reponse-"+i+"'><span class='racine'>"+el.mot.slice(0,el.t)+
								"</span><input type='text' name='reponse-"+i+"' id='input-"+i+"' class='reponse'><span></span></div>");
								return{ans:el.mot.slice(el.t),mot:el.mot};
						}):
						tab_mots.map((el,i)=>{
							texte=texte.replace(el.mot,"<div class='mot input'><span class='racine'>"+el.mot.slice(0,el.t)+"</span><div id='reponse-"+i+"' class='reponse d-inline-block'></div></div>");
							return {ans:el.mot.slice(el.t),mot:el.mot};
						});
					this.tab_prop=this.good_answer.map(el=>el.ans).duplicate().map(el=>{return {mot:el}});
					this.tab_prop.sort_rn();
				}else{
					let tab_texte=texte.split(" "),
						propositions=tab_texte.map((el,i)=>{
						tab_texte[i]+=" ";
						return {mot:el.toLowerCase(),i:i}
					}).filter(el=>tab_mots.includes(el.mot.toLowerCase())).sort_rn().slice(0, 10);

					for(let i=0;i<propositions.length;i++){
						let h="";
						if(propositions[i].i==0||tab_ponctuation.includes(tab_texte[propositions[i].i-1].slice(-2,-1))){
							h='<div class="reponse maj drop d-inline-block" id="reponse-'+i+'"></div> ';
						}else{
							h='<div class="reponse drop d-inline-block" id="reponse-'+i+'"></div> ';
						}
						tab_texte[propositions[i].i]=h;
					}
					propositions.sort((a,b)=> a.i-b.i);
					this.good_answer=propositions.map(el=>{return {ans:el.mot}});
					this.tab_prop=this.move?propositions.slice().sort_rn():tab_mots.map(el=>{return {mot:el}}).sort_rn();
					texte=tab_texte.join("");
					$("label").on("draginit dragstart dragover dragend",(ev)=>{
						ev.preventDefault();
						ev.stopPropagation();
					});
				}
				this.texte=regulariser(texte).split("||");
				this.$forceUpdate();
			},
			submit(ev){
				ev.preventDefault();
				let submited=[];
				this.empty_response=false;
				$("#texte .reponse").removeClass("border-warning");
				if((!this.input&&$("#texte .reponse").not(":has('label.ans input')").addClass("border-warning").length>0)||(this.input&&$("#texte input.reponse").filter((ind,el)=>$(el).val().length<0).addClass('border-warning').length>0)){
					this.empty_response=true;
					play(au.empty);					
					this.label="Tu dois remplir toutes les cases vides";
					return 0;
				}
				submited=$("#texte input").map((el,ind)=>$(ind).val()).get()
				for(let i=0;i<submited.length;i++){
					if(submited[i]===this.good_answer[i].ans.toLowerCase()){
						this.correct_answer++;
						if(this.input){
							$("#texte .mot.input").eq(i).addClass('cadre mr-1').append("<span class='valid ml-n2'></span>").find("input").addClass('w-0 bg-white text-success border-0');
						}else{
							$("#texte .drop label .mot, span.mot, .mot.input").eq(i).addClass('cadre mr-1').append("<span class='valid ml-2'></span>");
						}
					}else{
						this.fail_response=true;
						let m=this.terminaison?'mot':'ans';
						if(this.terminaison){
							let txt=$("#texte .mot.input").eq(i).addClass('w-0').find('.racine').text();
							
							$("#texte .drop label .mot, span.mot, .mot.input").eq(i).addClass('w-0 barre').before("<span class='mx-2'><span clas=''>"+txt+"</span><strong class='text-success'>"+this.good_answer[i].ans+"</strong></span>");
						}else{
							if(this.answerBefore){
								$("#texte .drop label .mot, span.mot, .mot.input").eq(i).addClass("barre w-0").parent().after("<span class='good mr-2'>"+this.good_answer[i][m]+"</span>");
							}else{
								$("#texte .drop label .mot, span.mot, .mot.input").eq(i).addClass("barre w-0").parent().before("<span class='good ml-2'>"+this.good_answer[i][m]+"</span>");
							}
						}
					}
					$("#texte .drop").eq(i).removeClass("reponse");
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					play(au.success);
					this.trouve=true;
				}if(typeof texts=="object"){
					this.form[this.index_qst].pass=true;
				}
				make_score(this);
				if(this.input){
					this.score=Math.round(this.score/tab_mots.length);
				}
				if(this.input){
					$("#texte .reponse input").attr("readonly",true);
				}
			},
			putAnswer(node){
				if(this.answerBefore){

				}
			}
		},
		watch:{}
	};
</script>