<template>
	<div class="row flex-column-reverse flex-md-row" id="choose-carac-of-sentence-table">
		<div class="col-md-9">
			<form class="inner multi not-solution" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<table class="table table-bordered border-perso">
						<thead>
							<tr class="table-info">
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody id="liste-phrases" class="input-field">
							<tr v-for="(ite,i) in form" :id="'phrase-'+i" class="phrase">
								<td class="px-1 py-1">{{ite.phrase}}</td>
								<td class="px-1 py-1 propos" :id="'reponses-'+i" style="">
									<label class="px-1 label ans" v-for="(item,j) in tab_prop[i]" :id="'lbl-'+i+j"><input type="radio" :name="'s'+i" :value=item v-model="response[i]" :disabled="trouve||fail_response"><div class="mot" :id="'mot-'+i+j">{{item}}</div></label>
								</td>
							</tr>
						</tbody>
					</table>
					<div id="comment" v-if="empty_response">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire:[{phrase:"",reponse:""}]
*
*
*
*
*
*/
	export default{
		data(){
			let data=datas(questionnaire);
			data.response=[];
			data.libelle=libelle;
			return data;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				this.empty_response=false;
				$(".propos").removeClass('border-warning');
				if($("#liste-phrases .propos").not(':has("input:checked")').addClass('border-warning').length>0){
					this.empty_response=true;
					this.label="Tu dois choisir une proposition dans chaque phrase.";
					$("#liste-phrases tr .propos").has(":not(label input:checked)").addClass('border-warning');
					play(au.empty);
					return 0;
				}
				for(let i=0;i<questionnaire.length;i++){
					let j=this.tab_prop[i].indexOf(this.response[i]);
					if(this.response[i]==questionnaire[i].reponse){
						$("#liste-phrases tr").eq(i).find(".propos label.ans").has("input:checked").addClass('mx-2').append("<span class='valid ml-n1'></span>");
						this.correct_answer++;
					}else{
						this.fail_response=true;
						$("#mot-"+i+j).addClass('barre');
						j=this.tab_prop[i].indexOf(questionnaire[i].reponse);
					}
					$("#mot-"+i+j).addClass("cadre");
				}
				$(".phrase label").removeClass("label");
				if(this.fail_response){
					play(au.fail);
					this.comment_faux="Tu as manqué "+(questionnaire.length-this.correct_answer)+" questions.";
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
			without_last(text){
				return text.without_last();
			},
		},
		created(){
				for(let i=0;i<questionnaire.length;i++){
					this.tab_prop.push(questionnaire[i].tabReponses.sort_rn());
				}
		},
		watch:{
			response(old,n){
				this.answered=$("#liste-phrases tr").has("input:checked").length;
			}
		}
	};
</script>