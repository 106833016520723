<template>
		<div class="row flex-column-reverse flex-md-row" id="drag-to-complete-sentence">
		<div class="col-md-9 text-center d-flex flex-column justify-content-between">
			<form class="inner multi col-lg px-0 d-flex flex-column" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="content-exercice my-auto">
						<table class="table table-bordered border-perso">
							<thead>
								<tr>
									<th class="text-danger border-danger" ondragover="alowDrop(event)" ondrop="remove_copy(event)" style="width: 35%!important"><i class="fa fa-trash"></i></th>
									<th class="table-info">Phrases</th>
								</tr>
							</thead>
							<tbody id="phrases">
								<tr class="corps pr-0 " v-for="(item,i) in form">
									<td v-if="i==0" :rowspan="form.length" class="px-0 py-1 border-danger align-items-center">
										<div class="d-flex flex-wrap flex-column justify-content-around" id="liste-propos">
											<label class="ans label border mx-1" :draggable="(!item.use||item.use&&!move)&&!(trouve||fail_response)" v-for="(ite,j) in tab_prop" :id="'label-'+j" ondragstart="drag(event)"><input :id="'input-'+j" :value="ite.mot" type="text"><div :class="['mot',{barre:ite.use&&move}]" draggable="false">{{ite.mot}}</div></label>
										</div>
									</td>
									<td class="px-1 text-left py-1 phrases maj">
										<span v-for="k in item.part.length-1">
										<span v-html="item.part[k-1]"></span><div :class="['reponse drop d-inline-block',{maj:(k-1==0&&item.part[k-1]=='')}]" :id="'reponse-'+i+'-'+k" @dragover.self="alowDrop" ondrop="copy(event)"></div>
									</span><span v-html="item.part.slice(-1).toString()"></span>
									</td>
								</tr>
							</tbody>
						</table>
						<div id="comment" v-if="empty_response">{{label}} </div>
					</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit"  :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer" :ad-track="adTrack"></trackcard>
	</div>
</template>
<script>

/*
*
*questionnaire:[{part:["",""]},mot:""]
*
*
*
*
*/
	export default{
		props:{
			libelle:{required:false,type:String,default:"Complète chaque phrase avec l'élément correspondant au choix : "},
			move:{type:Boolean, required:false, default:true},
			after:{default:true,required:false,type:Boolean},
			adTrack:{type:Boolean, required:false, default:false}
		},
		data(){
			let data=datas(questionnaire);
			return data;
		},
		created(){
			exercice_vue=this;
			this.tab_prop=questionnaire.map(el=>el.mot.split('/')).flat().duplicate().map(e=>{return{mot:e}}).sort_rn();
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				$("#phrases tr .phrases").has("label.ans").removeClass('border-warning').find(".reponse").removeClass("border-danger");
				if($("#phrases tr .phrases").not(":has(label.ans)").addClass('border-warning').length>0){
					this.empty_response=true;
					this.label="Tu dois remplir toutes les cases vides";
					play(au.empty);
					return 0;
				}
				$("#phrases .temporaly").remove();
				$("#phrases .phrases .reponse").addClass('bg-white border-0').find("label.ans input").map((el,ind)=>{
					if(questionnaire[el].mot.split('/').includes($(ind).val())){
						$(ind).siblings(".mot").addClass('text-orange mr-3').append("<span class='valid'></span>");
						this.correct_answer++;
					}else{
						this.fail_response=true;
						if(this.after){
							$(ind).siblings(".mot").addClass('barre').parent().parent().after("<strong class='text-success mx-2'>"+questionnaire[el].mot+" </strong> ");
						}else{
							$(ind).siblings(".mot").addClass('barre').parent().parent().before("<strong class='text-success mx-2'>"+questionnaire[el].mot+" </strong> ");
						}
					}
				});
				if(this.fail_response){
					play(au.fail);
				}else{
					play(au.success);
					this.trouve=true;
				}
				make_score(this);
			}
		},
	};
</script>