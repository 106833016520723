<template>
	<div class="row submit py-2 mx-0 d-flex">
		<div class="col-auto my-auto">
		    <div class="row">
				<button class="btn btn-success col-auto mx-2 px-5" :disabled="fail||trouve" @click.prevent="submit" v-if="!end">Valider</button>
				<a class="btn btn-primary px-3" :href="currentLecon+'/exercice-'+index_exo+'#game-zone'" @click="restart" v-else>Rejouer</a>
	      		<button type="button" class="btn btn-info col-auto mx-2 my-auto px-4" @click="next" id="nes-qst" v-if="nbreQuestion>1&&!end">Suivant</button>
		    </div>
		</div>
		<div class="col-lg text-center my-auto d-flex px-0">
			<h4 class="text-success my-auto py-2 mx-auto" v-if="trouve&&!end">{{congratulate}} !</h4>
			<h4 class="answer inner text-info my-auto py-2 mx-auto" v-if="fail&&nbreQuestion>1&&!end" id="comment-false">Ouppss! Tu l'as manqué </h4>

			<div class="w-100 text-center" v-if="end">
				<h5 id="estimate" class="text-success text-center">
					{{(score==100)?'Excellent! Compétence acquise.':(score>=75)?'Bravo! Tu as compris cette leçon':(score>=50)?'Passable! Compétence en cours d\'acquisition.':(score>10)?'Essaye encore! Compétence non acquise.':'Hmm! Tu dois apprendre intégralement cette leçon.'}}
				</h5>
				<div v-if="index_exo<nbre_exo||score<60">Vu ton score, nous t'invitons
					<span v-if="score<60">à <a class="" :href="currentLecon+'/exercice-'+index_exo+'#exercices'" @click="restart">rejouer</a></span>
					<span v-if="score>=60 && index_exo<nbre_exo"><span>à aller à l'<a class="link" :href="currentLecon+'/exercice-'+(1+index_exo)+'#exercices'">exercice suivant</a></span></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			fail:{type:Boolean, default:false},
			end:{type:Boolean, default:false},
			submit:{type:Function, required:true},
			trouve:{type:Boolean, default:false},
			next:{type:Function, required:false},
			nbreQuestion:{type:Number,default:1},
			score:{type:Number,required:true},
			nbre_exo:{type:Number,default:3}
		},
		data(){
			return{
				index_exo:parseInt(index_exo),
				congratulate:"Fantastique",
				currentLecon:currentLecon,
			}
		},
		watch:{
			trouve(on,n){
				this.congratulate=["Bravo","Bien joué","Super","Fantastique","Merveilleux","Félicitations","Très Bien","Excellent"].sort_rn()[0];
			}
		}
	};
</script>