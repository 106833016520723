<template>
	<div class="row flex-column-reverse flex-md-row" id="choose-question-and-answer">
		<div class="col-md-9 text-center">
			<form class="inner not-solution" id="game" @submit="submit(event)" action="#_">
				<div class="question">
						<div id="libelle" v-html="libelle">
						</div>
						<div class="card py-2" style="font-size: 1em!important;background: #fffdd2; color: #0950FF">{{form[index_qst].phrase}}</div>
					<div class="card mt-3">
						<div class="card-header">
							Question
						</div>
						<div class="card-body" id="question">
							<label :for="'res-'+i" :id="'label-'+i" class="ans" v-for="(item, i) in tab_question">
								<input type="radio" :value="item" name="s1" :id="'res-'+i" v-model="question" @change="selected='res-'+i" :disabled="trouve||fail_response"/>
								<div class="p-2 mot">{{item}}</div>
							</label><!--  -->
						</div>
					</div>
					<div class="card mt-3">
						<div class="card-header">
							Réponse
						</div>
						<div class="card-body text-left" id="phrases">
							<div v-for="(item,i) in tab_prop">
								<label class="ans answer">
									<input type="radio" v-model="response" :value="item.replace('<u>','').replace('</u>','')" :disabled="trouve||fail_response">
									<div class="mot maj ml-1" v-html="item"></div>
								</label>
							</div>
						</div>
					</div>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :passed="answered"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*questionnaire=[{phrase:"",question:i,tab_response:"html"}] i is index of question on tab_question, 
*   //tab_response is a array of html string, the answer must be the first one
*
*tab_question=["","",---,""]
*
*/
	export default{
		props:{
			libelle:{required:false, default:"Choisis la qustion adéquate puis sélectionne la réponse correspondante"}
		},
		data(){
			let data=datas(questionnaire);
			data.tab_sujet=tab_sujet.slice();
			data.tab_question=tab_question.slice();
			data.tab_verbe=tab_verbe.slice();
			data.tab_mot=[];
			data.question="";
			return data;
		},
		created(){
			this.initialiser();
		},
		methods:{
			next(){
				this.question="";
				$(".mot").removeClass('barre cadre');
				next_drag(this);
			},
			initialiser(){
				let index=this.index_qst, phrase=questionnaire[index].phrase,q=questionnaire[index].question;
				this.tab_prop=questionnaire[this.index_qst].tab_response.slice().sort_rn();
				this.index_solution=this.tab_question.indexOf(tab_question[q]);
			},
			drop(ev){
				drop(ev);
			},
			submit(ev){
				ev.preventDefault();
				let b_qst,b_resp;
				if(this.question==""||this.response==""){
					this.label="Tu dois choisir une question et la réponse";
					this.empty_response=true;
					play(au.empty);
					return -1;
				}
				if(b_qst=this.question===tab_question[questionnaire[this.index_qst].question]){
					$("#question input:checked +.mot").addClass('cadre');
				}else {
					$("#question input:checked +.mot").addClass('barre');
					$("#question input:not(:checked) +.mot").addClass('cadre');
					this.fail_response=true;
				}
				if(b_resp=this.response===questionnaire[this.index_qst].tab_response[0].replace("<u>", "").replace("</u>","")){
					$("#phrases input:checked + .mot").addClass('cadre').parent().before("<span class='valid'></span>");
				}else {
					this.fail_response=true;
					$("#phrases input:checked + .mot").addClass('barre');
					$("#phrases input").eq(this.tab_prop.indexOf(questionnaire[this.index_qst].tab_response[0])).next(".mot").addClass('cadre');
				}
				if(this.fail_response){
					play(au.fail);
				}else {
					play(au.success);
					this.trouve=true;
					this.correct_answer++;
				}
				questionnaire[this.index_qst].pass=true;
				make_score(this);
			}
		}
	};
</script>