<template>
	<div>
		<div class="py-3 px-2 text-left" style="text-align-last:initial" id="choose-good-text-for-text">
			<div class="main-quiz-holder">
				<div class="slide-container d-block py-3">
					<div class="py-3 px-3">
						<head-exercise :afficher-reponse="afficheReponse" :reponse-vraie='reponseVraie' :reponse-fausse="reponseFausse" :rejouer="rejouer"></head-exercise>
						<div class="code2">
							<div id="question">
								<div class="featureText1 ml-5" v-html="question"></div><br><br>
								<div class="rappel">
									<em>
										<u>Exemple:</u>
										<div class="encadre">
											<div class="math-box">
												<div class="d-flex">
													<fraction num="14" den="10"></fraction>
													<div>+</div>
													<fraction num="124" den="1000"></fraction>
													<div>=</div>
													<fraction num="1400" den="1000"></fraction>
													<div>+</div>
													<fraction num="124" den="1000"></fraction>
													<div>=</div>
													<fraction num="1524" den="1000"></fraction>
												</div>
											</div>
										</div>
									</em>
								</div>

								<div class="rappel"><em><u>Exemple</u>:<br></em><div class="encadre"><em><div class="math-box"><div class="vstack"><div style="top: -0.7em; ">14</div><div style="top: 0.686em; border-bottom: 1px solid #c2c2c2;">10</div><div class="baseline-fix"></div></div></div> + <div class="math-box"><div class="vstack"><div style="top: -0.7em; ">124</div><div style="top: 0.686em; border-bottom: 1px solid #c2c2c2;">1000</div><div class="baseline-fix"></div></div></div> <em style="margin: 0 0.5em">=</em> <div class="math-box"><div class="vstack"><div style="top: -0.7em; ">1400</div><div style="top: 0.686em; border-bottom: 1px solid #c2c2c2;">1000</div><div class="baseline-fix"></div></div></div> + <div class="math-box"><div class="vstack"><div style="top: -0.7em; ">124</div><div style="top: 0.686em; border-bottom: 1px solid #c2c2c2;">1000</div><div class="baseline-fix"></div></div></div> <em style="margin: 0 0.5em">=</em> <div class="math-box"><div class="vstack"><div style="top: -0.7em; ">1524</div><div style="top: 0.686em; border-bottom: 1px solid #c2c2c2;">1000</div><div class="baseline-fix"></div></div></div></em></div></div>

								<div class="d-flex align-items-center pl-4">
									<div v-for="(item,i) in fractions" class="d-flex justify-content-center">
										<span>
											<fraction :den="item.den" :num="item.num"></fraction>
										</span>
										<span class="" v-if="i<fractions.length-1">+</span>
									</div>
									<div>=</div>
									<div class="text-center">
										<div>
											<input type="text" @keyup="controlInput" class="form-control" id="input-num" :readonly="repondu" :disabled="repondu" autocomplete="off" v-model="reponse.num">
										</div>
										<div class="separateur"></div>
										<div>
											<input type="text" @keyup="controlInput" class="form-control" id="input-den" :readonly="repondu" :disabled="repondu" autocomplete="off" v-model="reponse.den">
										</div>
									</div>
								</div>
								<div v-show="afficherReponse">La bonne réponse est <fraction :den="bonneReponse.den" :num="bonneReponse.num"></fraction></div>
								
							</div>
							<div class="result-container notice col-md-5" v-show='reponseVide'>Entre une réponse valide!</div>
						</div>

						<div class="nav-container d-flex justify-content-center">
							<button class="btn next final" @click="soumettre" type="button">
								<i class="fa fa-check valid-color"></i>Valider
							</button>
						</div>
						
					</div>

					<div class="mt-3">
						<div class="btn-group d-flex">
							<button class="btn btn-info mx-1 btn-lg" @click="questionSuivante(-1)"> << Précédent</button>
							<button class="btn btn-warning mx-1 btn-lg" @click="questionSuivante(0)">Aléatoire</button>
							<button class="btn btn-primary flex-fill mx-1 btn-lg" @click="questionSuivante(1)">Suivant >></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/*
*
*questionnaire:[{fractions:[{d:a,n:b}], solution:{d:x,n:y}}]
*
*
*
*
*
*/
	export default{
		props:{
			input:{type:Boolean, required:false, default:false},
			notice:{type:String, required:false, default:''}
		},
		data:function () {
			return {
				question:'',
				operandes:[],
				reponse:{den:'',num:''},
				reponseVide:false,
				reponseFausse:false,
				reponseVraie:false,
				bonneReponse:0,
				repondu:false,
				index:0,
				afficherReponse:false,
			}
		},
		created(){
			this.initialiser();
		},
		methods:{
			questionSuivante(e){
				this.rejouer();
				this.index=e==1? (this.index+1) % questionnaire.length: 
					e==-1? Math.max(this.index-1,0):
					rn(0,questionnaire.length-1) ;	
				this.initialiser();
			},
			initialiser(){
				let i=this.index;
				if(this.input){
				}else{
					this.propositions=questionnaire[i].propositions;
				}
				this.fractions=questionnaire[i].fractions;
				this.bonneReponse=questionnaire[i].solution;
				this.question="Calcule.";
			},
			rejouer(){
				this.reponse='';
				this.reponseFausse=false;
				this.reponseVraie=false;
				this.reponseVide=false;
				this.repondu=false;
				this.afficherReponse=false;
				document.getElementById('input-reponse').focus();
			},
			soumettre(ev){
				if(this.reponse==''){
					this.reponseVide=true;
					play(au.empty);
					return 0;
				}
				this.repondu=true;
				if(this.reponse==this.bonneReponse){
					this.reponseVraie=true;
					play(au.success);
				}else{
					console.log('Soumis: ',this.reponse,this.bonneReponse);
					this.reponseFausse=true;
					play(au.fail);
				}
			},
			controlInput(ev){
				if((ev.keyCode<48 || ev.keyCode>57 )){
					ev.preventDefault();
				}
			},
			afficheReponse(){
				this.afficherReponse=true;
			}
		},
		watch:{
		}
	};
</script>