/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('exercice-list-general', require('./components/general/ExerciceListGeneral.vue').default);
Vue.component('navigation-game', require('./components/general/NavigationGame.vue').default);
Vue.component('end-section', require('./components/general/EndSection.vue').default);
Vue.component('trackcard', require('./components/general/TrackCard.vue').default);
Vue.component('submit-section', require('./components/general/SubmitSection.vue').default);
Vue.component('bottom-section', require('./components/general/BottomSection.vue').default);
Vue.component('header-cels', require('./components/general/HeaderCels.vue').default);
Vue.component('poster-subject', require('./components/general/PosterSubject.vue').default);
Vue.component('google-auto-text', require('./components/general/GoogleAutoText.vue').default);
Vue.component('google-auto-ad', require('./components/general/GoogleAutoAd.vue').default);
Vue.component('google-auto', require('./components/general/GoogleAuto.vue').default);
Vue.component('list-activity-correct', require('./components/general/ListActivityCorrect.vue').default);
Vue.component('card-item-link', require('./components/general/CardItemlink.vue').default);
Vue.component('solution-exercice', require('./components/general/SolutionExercice.vue').default);
Vue.component('head-exercise', require('./components/general/Headexercise.vue').default);
Vue.component('fraction', require('./components/general/Fraction.vue').default);
Vue.component('navigation-frame',require('./components/general/NavigationFrame.vue').default);


Vue.component('tableau-item-part-drag-drop', require('./components/exercices/TableauItemPartDragDrop.vue').default);
Vue.component('complete-texte-select', require('./components/exercices/CompleteTexteselect.vue').default);
Vue.component('select-good-proposition-block',require('./components/exercices/SelectGoodPropositionBlock.vue').default);
Vue.component('drag-word-to-build',require('./components/exercices/DragWordToBuild.vue').default);
Vue.component('select-good-word-from-list',require('./components/exercices/SelectGoodWordFromList.vue').default);
Vue.component('choose-type-phrase',require('./components/exercices/ChooseTypePhrase.vue').default);
Vue.component('choose-x-to-complete-phrase',require('./components/exercices/ChooseXToCompletePhrase.vue').default);
Vue.component('choose-good-word-on-sentences',require('./components/exercices/ChooseGoodWordOnSentences.vue').default);
Vue.component('drag-drop-between-words-table',require('./components/exercices/DragDropBetweenWordsTable.vue').default);
Vue.component('choose-radio-picture-string-random',require('./components/exercices/ChooseRadioPictureStringRandom.vue').default);
Vue.component('choose-question-and-answer',require('./components/exercices/ChooseQuestionAndAnswer.vue').default);
Vue.component('order-words-in-cells',require('./components/exercices/OrderWordsInCells.vue').default);
Vue.component('drag-to-complete-sentence',require('./components/exercices/DragToCompleteSentence.vue').default);
Vue.component('choose-caract-of-sentence-table',require('./components/exercices/ChooseCaractOfSentenceTable.vue').default);
Vue.component('select-on-second-cel-table',require('./components/exercices/SelectOnSecondCelTable.vue').default);
Vue.component('check-words-of-text',require('./components/exercices/CheckWordsOfText.vue').default);
Vue.component('classify-words',require('./components/exercices/ClassifyWords.vue').default);
Vue.component('complete-text-drag-drop-table',require('./components/exercices/CompleteTextDragDropTable.vue').default);
Vue.component('variant-words-table',require('./components/exercices/VariantWordsTable.vue').default);
Vue.component('complete-terminaison-determinant-table',require('./components/exercices/CompleteTerminaisonDeterminantTable.vue').default);
Vue.component('categorise-words-of-sentences-table',require('./components/exercices/CategoriseWordsOfSentencesTable.vue').default);
Vue.component('check-good-phrases',require('./components/exercices/CheckGoodPhrases.vue').default);
Vue.component('choose-good-answer-for-image',require('./components/exercices/ChooseGoodAnswerForImage.vue').default);
Vue.component('choose-good-answer-for-text',require('./components/exercices/ChooseGoodAnswerForText.vue').default);
Vue.component('complete-collection',require('./components/exercices/CompleteCollection.vue').default);
Vue.component('compare-collection',require('./components/exercices/CompareCollection.vue').default);
Vue.component('reduce-collection',require('./components/exercices/ReduceCollection.vue').default);
Vue.component('image-input-column',require('./components/exercices/ImageInputColumn.vue').default);
Vue.component('order-number',require('./components/exercices/OrderNumber.vue').default);
Vue.component('comparer-ranger',require('./components/exercices/ComparerRanger.vue').default);
Vue.component('multiplie-quiz',require('./components/exercices/MultiplieQuiz.vue').default);
Vue.component('multiplication-mixtes',require('./components/exercices/MultiplicationMixtes.vue').default);
Vue.component('comparaison-collections',require('./components/exercices/ComparaisonCollections.vue').default);
Vue.component('addition-soustraction',require('./components/exercices/AdditionSoustraction.vue').default);
Vue.component('addition-fractions',require('./components/exercices/AdditionFractions.vue').default);
Vue.component('problemes',require('./components/exercices/Problemes.vue').default);

Vue.component('conjuguer-er-present-exercice1', require('./components/exercices/ConjuguerErPresentExercice1.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


Vue.prototype.beepSon = function() {
	let beep=new Audio();
	beep.src=	"/assets/sounds/beep.mp3";
	beep.play();
};

Vue.prototype.restart = function() {
	location.reload();
	Object.assign(this.$data,)
	return location;
};
Vue.prototype.alowDrop = function(ev) {
	alowDrop(ev);
};
Vue.prototype.copy = function(ev) {
	copy(ev);
	if($(ev.target).has("label.ans input")){
		$(ev.target).removeClass("border-warning");
	}
};
Vue.prototype.drag = function(ev) {
	ev.dataTransfer.setData("text/plain",ev.target.id);
	event.dataTransfer.effectAllowed = "copy";
	ev.dataTransfer.setData("text/parent",ev.target.id);
};
  // centers the el at (x, y) coordinates
jQuery.prototype.moveAt = function(x,y) {
	let offset=this.offset();
	this.offset({
		left: x-this.outerWidth() / 2,
		top: y-this.outerHeight() / 2
	});
	offset=this.offset();
};
Vue.prototype.remove_copy = function(ev) {
	remove_copy(ev);
};
Vue.prototype.get_id_cel = function(txt) {
	return get_id_cel(txt);
};

jQuery(document).ready(function($) {	
});
Vue.prototype.classWords = function(word,label='') {
	return ['’',"'"].includes(word.slice(-1))?('ml-1 mr-n1 '+ label):['.','!','?',',',';',':','»','«'].includes(word)?'mr-1 ml-n1':'mx-1 '+label;
};
Vue.prototype.move = function(ev) {
	let idElt=ev.dataTransfer.getData('text/plain');
	$("#"+idElt).detach().addClass('col-2').appendTo(ev.target) /*.moveAt(ev.pageX,ev.pageY)*/;
	if(ev.target.id.includes("recycle-bin")){
		$("#"+idElt).removeClass('position-static col-2').addClass('col-3 position-absolute').moveAt(ev.pageX,ev.pageY);
	}else{
		$("#"+idElt).removeClass('col-3 position-absolute').addClass('position-static col-2');
	}
};

Array.prototype.chunk = function(size) {
	let res=[];
	for(let i=0;i<this.length;i+=size){
		res.push(this.slice(i,i+size));
	}
	return res;
};

var busy=false;
$(window).scroll(function(){
	if($(window).scrollTop()>300){
		$('#scrollUp').stop().animate({opacity:0.05},400)
	}else{
		$('#scrollUp').stop().animate({opacity:0.00},400)
	}if($(window).scrollTop()+$(window).height()>$('#main-content').offset().top+$('#main-content').height()-50 &&!busy){
		busy=true;
		let link=$('.pagination .nextpage').attr('href');
		if(typeof link!='undefined'){
			var params='smt_layout='+$('body').attr('layout')+'&ajaxpage=1';
			$.ajax({
				type:"POST",
				cache:false,
				async:false,
				data:params,
				url:link,
				dataType:'json',
				success:function(data){
					if(data!=""){
						$('.pagination .nextpage').parent().append('<span class="thispage">Page '+$('.pagination .nextpage').attr('alt')+'</span>');
						$('.pagination .nextpage').remove();
						$("#main_content").append(data['content']);
						busy=false
					}
				}
			})
		}
	}
});

$(document).ready(function(){

	const app = new Vue({
	    el: '#app',
		data:{
			loaded:false,
		},
	    mounted(){
	    	this.loaded=true;
	    	document.getElementById("vue-loader").style.display = 'none';
	    	document.getElementById("vue-block").style.display = 'initial';
	    }
	});

	const app2=new Vue({
		el:'#app2',
	});

    d=new Date();
    $("#copy").text(d.getFullYear());
    $('.menu-topmenu-container li a').each(function() {
        $('<option />', {
            'value':$(this).attr('href'),
            'text':$(this).html()
        }).appendTo($('#mobile-sec-menu'));
    });
    jQuery('.menu-primary li a').each(function() {
        jQuery('<option />', {
            'value':jQuery(this).attr('href'),
            'text':jQuery(this).html()
        }).appendTo(jQuery('#mobile-main-menu'));
    });$('.navbar .dropdown > a').click((ev)=>{ev.preventDefault(); location.href =$(ev.target).attr("href")});
    jQuery('select.mobile-menu').on('change',function(){window.location=jQuery(this).find("option:selected").val()});

    $('a[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        placement: 'top',
        html: true
    });

    let vue_newsletter=new Vue({
        el:"#form-newsletter",
        data:{
            prenom:'',
            email:'',
            token:'',
            resultStatus:'terxt-muted',
            comment:'Abonnez vous à la lettre d\'information',
            errorPrenom:false,
            errorEmail:false
        },
        methods:{
            subscribe(ev){
                if(this.prenom.length<1 || this.email.length<1){
                    this.errorEmail=this.email.length<1;
                    this.errorPrenom=this.prenom.length<1;
                    return 0;
                }else{
                    axios.post('/news-letter',{prenom: this.prenom,email:this.email},{headers:{'X-CSRF-TOKEN': this.token}}).then(response=>{
                        this.resultStatus=response.status==200?'text-success':'text-warning';
                        this.comment=JSON.parse(data);
                    }).catch(error=>{
                        console.log(error);
                  });;
                }
            }
        }
    });

	$('#scrollUp').bind('mouseenter',function(ev){
		$(this).stop().animate({
			opacity:0.1
		},"slow")
	}).bind('mouseout',function(){
		let o=$(window).scrollTop()>300?0.05:0.00;
		$(this).stop().animate({opacity:o},"slow")
	});
	$('#scrollUp').on('click',function(){
		$('html, body').animate({scrollTop: 0}, 1200);
	});
});