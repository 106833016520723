<template>
	<thead id="toiture" class="align-items-center">
		<tr class="text-center">
			<th class="w-25 border-danger" @dragover="alowDrop" @drop="remove_copy"><i class="fa fa-trash text-danger"></i></th>
			<th class="table-info px-0 th-cel" v-for="cel in tableCels" :style="{width:75/tableCels.length+'%'}">{{cel}}</th>
			<slot></slot>
		</tr>
	</thead>
</template>
<script>
	export default{
		props:{tableCels:{required:true, type:Array}}
	};
</script>