<template>
		<div class="row flex-column-reverse flex-md-row" id="check-elements-on-list">
		<div class="col-md-9">
			<form class="inner multi not-solution" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="row no-gutters" id="liste-mots">
						<label :class="[{noms:!ponctuation.includes(item)},classWords(item)]" :id="'nom-'+i" v-for="(item,i) in tab_prop">
							<input type="checkbox" name="noms" :value="item+i" v-model="response" :id="'input-'+i" :disabled="response.length>ab-1&&response.indexOf(item+i)===-1||(trouve||fail_response)||ponctuation.includes(item)" @change="answered=response.length" :data-value='item'>
							<div class="mot">{{item}}</div>
							<sup class="text-info" v-if="response.indexOf(item+i)>-1">{{response.indexOf(item+i)+1}}</sup>
						</label>
					</div>
					<div id="comment" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit"  :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire:[""]
*
*libelle:""
*
*tab_answer:["",""]
*
*/
	export default{
		data(){
			let data=datas(questionnaire);
			data.response=[];
			data.libelle=libelle;
			data.ab=tab_answer.length;
			return data;
		},
		created(){
			exercice_vue=this;
			this.tab_prop=questionnaire[0].split(" ");
			// this.tab_prop.sort_rn();
		},
		methods:{
			submit(ev){
			
				ev.preventDefault();	
				this.empty_response=false;
				if(this.response.length<tab_answer.length){
					this.empty_response=true;
					this.label="Tu dois selectionner "+tab_answer.length+" éléments.";
					play(au.empty);
					return 0;
				}
				$("#liste-mots label input:checked").each(function(index, el) {
					if(tab_answer.includes($(el).data('value'))){
						exercice_vue.correct_answer++;
						$(el).parent().find(".mot").addClass('cadre').after("<span class='valid'></span>");
					}else{
						$(el).parent().find('.mot').addClass('barre');
						exercice_vue.fail_response=true;
					}
				});
				if(this.fail_response){
					$("#liste-mots .mot").each((i)=>{
						let nom=$("#input-"+i).data('value');
						if(tab_answer.includes(nom)){
							$("#nom-"+i+" .mot").addClass("cadre");
						}
					});
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);

			},
			set_answered(ev){
				this.answered=$("#liste-mots label.noms input:checked").length;
			}
		},
		watch:{}
	};
</script>