<template>
	<div class="fraction text-center">
		<div v-if="num.length==0">
			<input type="number" class="form-control" autocomplete="off">
		</div>
		<div class="numerateur-fraction" v-else>{{num}}</div>
		<div class="separateur"></div>
		<div v-if="den.length==0">
			<input type="number" class="form-control" autocomplete="off">
		</div>
		<div class="denominateur-fraction" v-else>{{den}}</div>
	</div>
</template>
<script type="text/javascript">
	export default{
		props:{
			num:{type:String, required:false, default:''},
			den:{type:String, required:false, default:''}
		}
	};
</script>