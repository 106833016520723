<template>
		<div class="row flex-column-reverse flex-md-row" id="multiplication-mixtes">
		<div class="col-md-9 text-center d-flex flex-column justify-content-between">
			<form class="inner col-lg px-0 d-flex flex-column" id="game">
				<div class="question" id="question">
					<div id="libelle">Complète les multiplications suivantes:</div>
					<div class="content-exercice my-auto">
						<div>
							<ul id="list-questions">
								<li v-for="(item,i) in tabQuestions" :class="[{'border border-primary':trouve||fail_response, 'border border-warning':empty_response&&tabReponses[i]==undefined}]">
									<span v-if="item.equal">{{item.operation}}</span>
									<input type="number" :name="'input-'+i" :size="Math.ceil(item.answer/10)" v-model="tabReponses[i]" class="" v-if="!(trouve||fail_response)">
									<span v-else>
										<span class="barre mr-1" v-if="item.answer!=tabReponses[i]">({{tabReponses[i]}})</span>
										<span class="text-success">{{item.answer}}</span>
									</span>
									<span v-if="!item.equal">{{item.operation}}</span>
								</li>
							</ul>
						</div>
						<div id="comment" v-if="empty_response">Tu dois remplir toutes les cases vides!</div>
					</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit"  :score="score" :passed="1" :indexExo="1	"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/*
*
*questionnaire:[{part:["",""]},mot:""]
*
*
*
*
*/
var nbreQuestion=24;
	export default{
		props:{
			facteurs:{type:String, required:true, default:[2,3]}
		},
		data(){
			return {
				tabQuestions:[],
				tabReponses:new Array(nbreQuestion),
				passed:1,
				score:0,
				end:false,
				trouve:false,
				fail_response:false,
				answered:0,
				correct_answer:0,
				empty_response:false,
				index_exo:1,
			}
		},
		mounted(){
			$("#list-questions input").eq(0).focus();
		},
		created(){
			let tab=[1,2,3,4,5,6,7,8,9,10,11,12], facts=this.facteurs.split('-');
			this.tabQuestions=facts.map(e=>{
				return tab.map(el=>{
					let equal=!!rn(0,1), op,ans;
					op=equal?el+" x "+e+" = ":" x "+e+" = "+(el*e);
					ans=equal?el*e:el;
					return {operation:op, equal:equal, answer:ans}
				});
			}).flat().sort_rn().slice(0,nbreQuestion);
		},
		watch:{
			tabReponses(old,ne){
				this.answered=ne.filter(el=>el!=undefined).length;
			}
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				if($("#list-questions li").filter((i,el)=>$(el).find('input').val().length<1).length>0){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				this.empty_response=false;
				for(let i=0; i<this.tabReponses.length; i++){
					if(this.tabQuestions.answer==this.tabReponses){
						this.correct_answer++;
					}else{
						this.fail_response=true;
					}
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			}
		},
	};
</script>