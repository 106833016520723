<template>
	<div class="mt-3 navigation-frame justify-content-end row no-gutters mr-5">
		<div class="btn-group">
			<button class="btn btn-info mx-1 btn-prev" @click="questionSuivante(-1)"> << Précédent</button>
			<button class="btn btn-warning mx-1 btn-rand" @click="questionSuivante(0)">Aléatoire</button>
			<button class="btn btn-primary flex-fill mx-1 btn-next" @click="questionSuivante(1)">Suivant >></button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		props:{
			questionSuivante:{type:Function, required:true}
		}
	};
</script>