<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- Auto text Ads -->

<template>
	<div>
		<ins class="adsbygoogle"
		     style="display:block"
		     data-ad-client="ca-pub-5021097477076063"
		     data-ad-slot="4737853230"
		     data-ad-format="auto"
		     data-full-width-responsive="true"></ins>
	</div>
</template>
<script>
	export default{
		mounted(){
			jQuery.ajax({
		        url: "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
		        dataType: 'script',
		        success(){(adsbygoogle = window.adsbygoogle || []).push({});},
		        async: true
		    });
			
		}
	};
</script>
