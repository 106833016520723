<template>
	<div class="row flex-column-reverse flex-md-row" id="order-number">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question multi">
					<!-- <div id="libelle" v-html="libelle"></div> -->
					<div class="">
						<div class="d-flex justify-content-center">
							<div class="col-10" id="back" :style="ab">
								<div class="d-flex flex-column justify-content-around h-100">
									<div class="text-center">
										<u><h2 class="text-info">Je compte de {{min}} à {{max}}</h2></u>
										<p>Range les nombres de {{min}} à {{max}}.</p>
									</div>
									<div >
										<div class="my-3">
											<div class="d-flex justify-content-center align-content-stretch" id="answer">
												<div v-for="(item,i) in tab_prop.slice().sort((a,b)=>a.nbre-b.nbre)" class="mx-1 border border-danger case text-center" @dragover="alowDrop" @drop="drop" :id="'case-'+i">
													<div v-if="item.st" class="bg-dark border border-danger">
														<input type="number" name="" :value="item.nbre">
														<div class="text-white py-2 px-3">{{item.nbre}}</div>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div class="d-flex justify-content-center border h-25 w-100" id="list-numbers" @dragover="alowDrop" @drop="drop">
												<div v-for="item in tab_prop.filter(el=>!el.st)" class="border border-white bg-dark ans mx-1" :draggable="!(trouve||fail_response)" ondragstart="drag(event)" :id="'number-'+item.nbre">
													<input type="number" name="" :value="item.nbre">
													<div class="text-white p-2">{{item.nbre}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<solution-exercice :fail-response="fail_response" :answer="answer.join(' < ')"></solution-exercice>
					</div>

					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire=[{i:0}]
*
*tab:[0,1,2,...]
*
*
*
*/
	export default{
		props:{
			max:{type:Number, required:false, default:100},
			min:{type:Number, required:false, default:1},
		},
		data(){
			let data=datas(questionnaire);
			data.nbre_question=questionnaire.length;
			data.libelle="Range les nombres de "+this.min+" à "+this.max;
			data.label="Tu dois ranger tous les nombres dans les cases correspondantes.";
			return data;
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		methods:{
			next(){
				next_qst(this);
			},
			initialiser(){
				let tab=[];
				for(let i=this.min;i<=this.max;i++){
					tab.push(i);
				}
				this.tab_prop=tab.sort_rn().slice(0,10).map((el,)=>{return {nbre:el}});
				let stat=Math.ceil(this.tab_prop.length/4);
				for(let i=0;i<stat;i++){
					this.tab_prop[i].st=true;
				}
				this.answer=this.tab_prop.map(el=>el.nbre).sort_lt();
				this.ab={'background-image':"url('/public/assets/images/back/back-"+rn(1,5)+".png')", height:'520px','background-size':'cover'};
				$("#answer .ans").addClass('mx-1').detach().appendTo('#list-numbers');
			},
			submit(ev){
				ev.preventDefault();
				let submited=$("#answer .case input").map((i,el)=>$(el).val()).get();
				if(submited.length < this.tab_prop.length){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				if(submited.join(",")==this.answer.join(",")){
					this.correct_answer++;
					this.trouve=true;
					play(au.success);
				}else{
					this.fail_response=true;
					play(au.fail);
				}
				make_score(this);
			},
			drop(ev){
				let idElt=ev.dataTransfer.getData('text/plain');
				$("#"+idElt).detach().removeClass('mx-1').appendTo(ev.target);
			}
		},
		watch:{}
	};
</script>