<template>
	<div id="head-exercise">
		<div class="d-flex justify-content-around" v-if="reponseFausse||reponseVraie">
			<div class="correct" v-show="reponseVraie"><span>Correct</span></div>
			<div v-show="reponseFausse">
				<div class="wrong d-inline-block mx-1">
					<span>Incorrect</span>
				</div>
				<button class="btn btn-primary mx-1" @click="afficherReponse">Montrer la réponse</button>
			</div>
			<button class="btn question-number" @click="rejouer">Rejouer</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		props:{
			afficherReponse:{type:Function, required:true},
			rejouer:{type:Function, required:true},
			reponseVraie:{type:Boolean, required:true, default:false},
			reponseFausse:{type:Boolean, required:true, default:false}
		}
	};
</script>