<template>
	<div class="row flex-column-reverse flex-md-row" id="compare-collection">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="" >
						<div id="corps" class=" p-3" style="background:#edeee0;">
							<div class="row no-gutters justify-content-around" style="border: 2px solid #dcddca">
								<div class="col-5 p-2 bg-grey w-100">
									<div class="bg-light h-100">
										<div class="d-flex align-items-center h-100">
											<div class="flex-grow-1">
												<div class="row no-gutters mr-2 justify-content-around">
													<div v-for="i in quantite1" class="col-3">
														<img :src="'/public/assets/images/fr/jeux/la-logique/comparaisons/'+obj1+'.png'" :alt="'Comparer collections - images quantité 1 - '+i" class="w-100 m-1">
													</div>
												</div>
											</div>
											<div class="flex-grow-0">
												<div class="border border-light bg-dark text-white rounded px-3 py-1">{{quantite1}}</div>
											</div>
										</div>
									</div>
								</div>
								<div :class="['text-center align-self-center']" style="min-width:2rem; line-height:1; font-size:2em">
									<div :class="['bg-light border rounded',{'border-danger':fail_response, 'border-success':trouve,'border-dark':!(trouve||fail_response)}]">
										<span :class="[{'text-success':trouve||fail_response}]">{{signe}}</span>
									</div> 
								</div>
								<div class="col-5 p-2 bg-grey">
									<div class="bg-light h-100">
										<div class="d-flex align-items-center h-100">
											<div class="flex-grow-0">
												<div class="border border-light bg-dark text-white rounded px-3 py-1">{{quantite2}}</div>	
											</div>
											<div class="flex-grow-1">
												<div class="row no-gutters ml-2 justify-content-around">
													<div v-for="i in quantite2" class="col-3">
														<img :src="'/public/assets/images/fr/jeux/la-logique/comparaisons/'+obj2+'.png'" :alt="'Comparer collections - images quantité 2 - '+i" class="w-100 m-1">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="signes" class="d-flex input-field mt-3 justify-content-center">
								<label v-for="(item,i) in tab_prop" :for="'input-'+i" :class="['ans col-auto m-0 px-2 py-1 text-center mx-2 border border-primary rounded',{'bg-white':response==item.val}]" style="font-size:1.8em; line-height:1">
									<input type="radio" name="s1" v-model="response" :id="'input-'+i" :disabled="trouve||fail_response" :value="item.val" readonly="true">
									<div>{{item.signe}}</div>
								</label>
							</div>
						</div>
					</div>
					<div>
						<div id="comment" class="mt-3" v-if="empty_response">Tu dois choisir le signe correspondant!</div>
						<div class="text-muted">
							<span>{{quantite1}}</span> 
							<span> est <span v-if="response.length<1">____</span> 
							<strong  :class="[{'text-success':fail_response||trouve,}]">
								<span :class="[{'text-danger barre':fail_response}]">{{response}}</span>
								<span class="" v-if="fail_response">{{solution}}</span>
							</strong>  
							</span> {{quantite2}}</div>
					</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
var questionnaire=[];
/*
*
*tab_prop:[""] list of words wich we can remove from text
*
*text:""
*
*propositons:undefined
*
*
*/
	export default{
		props:{
			equal:{type:Boolean, required: false, default:true},
			libelle:{type:String, required:false, default:"Choisis le signe qui convient :"},
			min:{type:Number, required:false, default:rn(2,5)},
			max:{type:Number, required:false, default:16}
		},
		data(){
			let data=datas(questionnaire);	
			data.nbre_question=2;
			data.quantite1=0;
			data.quantite2=0;
			data.obj1='';
			data.obj2='';
			data.signe='';
			return data;
		},
		created(){
			exercice_vue=this;
			for(let i=this.min; i<=this.max;i++){
				questionnaire.push({nbre:i});
			}
			this.tab_prop=[{signe:'<',val:'plus petit que'},{signe:'>',val:'plus grand que'}];
			if(this.equal){
				this.tab_prop.push({signe:'=',val:'égal à'});
			}
			this.tab_prop.sort_rn();
			this.initialiser();
		},
		mounted(){
		},
		computed:{
		},
		methods:{
			next(){
				next_qst(this);
			},
			initialiser(){
				let tabObj=[1,6,11,15].sort_rn(), temp;
				this.obj2=tabObj[0]
				this.obj1=[1];
				this.quantite1=questionnaire[this.index_qst].nbre;
				do{
					temp=rn(Math.max(this.min,this.quantite1-2), Math.min(this.max,this.quantite1+2));
				}while(!this.equal && temp==this.quantite1);
				this.quantite2=temp;
				this.solution=this.quantite1<this.quantite2?'plus petit que':this.quantite1>this.quantite2?'plus grand que':"égal à";
				this.response='';
			},

			submit(ev){
				ev.preventDefault();
				this.empty_response=false;
				if(this.response==''){
					play(au.empty);
					this.empty_response=true;
					return 0;
				}
				console.log(this.response, this.solution);
				if(this.response==this.solution){
					this.correct_answer++;
					play(au.success);
					this.trouve=true;
				}else{
					this.fail_response=true;
					play(au.fail);
				}
				this.nbre_question++;
				this.signe=this.gsigne(this.solution);
				// this.response=this.solution;
				make_score(this,true);
			},
			gsigne(word){
				if(word.length>0)
					return this.tab_prop.filter(el=>el.val==word)[0].signe;
				else return "";
			}
		},
		watch:{
			response(ne, old){
				this.signe=this.gsigne(ne);
			}
		}
	};
</script>