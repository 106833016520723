<template>
	<div class="row flex-column-reverse flex-md-row" id="complete-text-drag-drop-table">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution multi" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="" >
						<div id="corps" class="d-flex flex-column justify-content-around p-3" style="height:340px; background:#edeee0;">
							<div class="text-center py-2" style="color:#62625f">Fais correspondre la collection au chiffre associè.</div>
							<div class="bg-white row no-gutters p-2 flex-fill" style="border: 2px solid #dcddca">
								<div class="col-7 m-0">
									<div class="h-100 d-flex align-content-start position-relative">
										<div class="pt-4 w-100">
											<div class="border border-white h-100 px-1 pb-2 shadow" style="padding-top:2.25rem; background:#cccccc;">
												<div class="row no-gutters justify-content-around align-content-start h-100" id="answer" style="z-index:4" @dragover="alowDrop" @drop="move">
													<label v-for="i in ab" :id="'lbl-'+i" class="label m-1 col-2" ondragstart="drag(event)" :draggable="!(trouve||fail_response)" style="z-index:45">
														<img :src="'/public/assets/images/fr/'+img+'.png'" width="100px" draggable="false">
													</label> 
												</div>
											</div>
										</div>
										<div v-if="typeof(form[index_qst].img)=='string'">
											<img :src="'/public/assets/images/fr/'+form[index_qst].img+'.png'" :alt="'images-les enfants jouent-'+form[index_qst].answer">
										</div>
										<div class="position-absolute w-100 mb-n2" style="top:0; left:0; z-index:3" v-else>
											<div class="d-flex justify-content-center p-1">
												<div class="d-inline-block text-white px-4 shadow" style="font-size:2.4em; border:2px solid #b9b9b9;background:#333333; border-radius:20px">{{form[index_qst].answer}}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col pl-5">
									<div class="h-100 w-100 no-gutters" @dragover.self="alowDrop" @drop.self="move" style="background-image:url('../../assets/images/corbeille.png'); background-repeat:no-repeat;background-size:contain" id="recycle-bin"></div>
								</div>
							</div>
						</div>
					</div>

					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*tab_prop:[""] list of words wich we can remove from text
*
*text:""
*propositons:undefined
*
*
*/
	export default{
		props:{
			libelle:{required:false, default:"Complète la collection pour qu'elle corresponde au nombre associè.", type:String},
			max:{type:Number, required:true},
			min:{type:Number, required:false, default:1},
			reduce:{type:Boolean, required:false, default:false}
		},
		data(){
			let data=datas(questionnaire);
			data.nbre_question=questionnaire.length;
			data.label="Tu dois ajouter ou diminuer des éléments dans la case.";
			return data;
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		mounted(){
		},
		methods:{
			next(){
				$("label.copy").remove();
				$("#answer label").removeClass('bg-warning');
				next_qst(this);
			},
			initialiser(){
				this.img='jeux/aliments/'+rn(1,16);
				this.ab=this.max+3;
				$("label").removeClass('opacity-5');
				$("label.ans").detach().appendTo('#propos');
				$("#recycle-bin label").toggleClass('position-static position-absolute col-3 col-2').detach().appendTo('#answer');
			},
			submit(ev){
				ev.preventDefault();
				let submited=[];
				this.empty_response=false;
				if($("#answer label").length==this.ab && $("#answer label").length!=questionnaire[this.index_qst].answer){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				if($("#answer label").length!=questionnaire[this.index_qst].answer){
					this.fail_response=true;
					play(au.fail);
					for(let i=$("#answer label").length; i<questionnaire[this.index_qst].answer; i++){
						let clone=$("#lbl-1").clone();
						clone.attr('id',"lbl-1-"+i).addClass('bg-info p-1 copy').appendTo("#answer");
					}
					for(let i=questionnaire[this.index_qst].answer; i<$("#answer label").length; i++){
						$("#answer label").eq(i-1).addClass('p-1 bg-warning');
					}
				}else{
					this.trouve=true;
					play(au.success);
					this.correct_answer++;
				}
				$("#propos label").addClass('opacity-5');
				make_score(this);
			},
			move(ev){
				let idElt=ev.dataTransfer.getData('text/plain');
				$("#"+idElt).detach().addClass('col-2').appendTo(ev.target);
				if(ev.target.id.includes("recycle-bin")){
					$("#"+idElt).removeClass('position-static col-2').addClass('col-3 position-absolute').moveAt(ev.pageX,ev.pageY);
				}else{
					$("#"+idElt).removeClass('col-3 position-absolute').addClass('position-static col-2');
				}
			}
		},
		watch:{}
	};
</script>