<template>
	<div class="row flex-column-reverse flex-md-row" id="">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner not-solution" id="game" @submit="submit(event)" action="#_">
				<div class="question">
					<div id="libelle" v-html="libelle"></div>
					<div class="content-exercice">
						<div id="phrases">
							<div v-for="(item, i) in form" class="phrase">
								<input type="checkbox" name="s1" :value="item.phrase" :id="'label-'+i" v-model="response" :disabled="trouve||fail_response||(response.length>good_answer.length-1&&!response.includes(item.phrase))" @change="answered=response.length">
								<label :for="'label-'+i" class="ans">{{item.phrase}}</label>
								<sup v-if="response.includes(item.phrase)">{{response.indexOf(item.phrase)+1}}</sup>
							</div>
						</div>
					</div>
					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	/*
	*
	*libelle=""
	*
	*
	*
	*
	*
	*
	*/
	export default{
		data(){
			let data=datas(questionnaire);
			data.libelle=libelle;
			data.good_answer=questionnaire.filter(el=>el.ok).map(el=>el.phrase);
			data.response=[];
			return data;
		},
		created(){
			exercice_vue=this;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				if(this.response.length<this.good_answer.length){
					this.empty_response=true;
					this.label="Tu dois selectionner "+this.good_answer.length+" phrases!";
					play(au.empty);
					return 0;
				}
				$("#phrases .phrase input:checked").each(function(index, el) {
					if(exercice_vue.good_answer.includes($(el).val())){
						exercice_vue.correct_answer++;
						$(el).before("<span class='valid'></span>");
					}else{
						exercice_vue.fail_response=true;
						$(el).siblings('label').addClass('barre');
					}
				});
				if(this.fail_response){
					$("#phrases .phrase input").each(function(index, el) {
						if(exercice_vue.good_answer.includes($(el).val())){
							$(el).siblings("label").addClass("good");
						}
					});
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
			make_score(this);
			}
		}
	};
</script>