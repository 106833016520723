<template>
	<div class="row flex-column-reverse flex-md-row" id="drag-word-to-build">
		<div class="">
			<form class="inner multi" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<table class="table table-bordered text-center">
						<thead>
							<tr>
								<th class="text-center text-danger border border-danger col-auto px-1 border-bottom-0 w-25" id="div-rest" ondragover="alowDrop(event)" @drop="remove">Supprimer</th>
								<th class="table-info"></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border-danger border-top-0 text-danger" ondragover="alowDrop(event)" @drop="remove"><i class="fa fa-trash"></i></td>
								<td class="px-0">
									<div class="corps input-field pl-2 row justify-content-center" id="phrase" style="font-size: 1.2em">
										<div :id="'reponse-corps-'+i" class="square col-auto mr-1 p-0 reponse border-gold" @dragover.self="alowDrop" @drop.self="copy" v-for="i in tab_prop.length" :key="i">
										</div><strong>{{form[index_qst].good_answer.slice(-1)}}</strong>
									</div>
									<div id="input-field" class="mt-4 text-center">
										<label class="col-auto ans border m-0 px-1 ml-1" v-for="(item,i) in tab_prop" :key="i" :draggable="!item.use && !(fail_response||trouve)" ondragstart="drag(event)" :id="'lbl-'+i"><input type="text" :value="item.mot" name="s1" :readonly="true"/><div :class="['mot',{barre:item.use}]">{{item.mot}}</div></label>
									</div><div class="text-navy text-left border-info pl-2 mt-3" v-if=fail_response><u style="color: #ff3965">Solution:</u> {{good_answer}}</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div id="comment" v-if="empty_response" class="mt-3">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :passed="answered" :matiere="matiere"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		props:{libelle:{type:String, default:"Arrange les mots et forme des phrases correctes.", required:false}},
		data(){
			let d=datas(questionnaire);
			d.matiere=matiere;
			d.nbre_question=questionnaire.length;
			return d;
		},
		methods:{
			remove(ev){
				remove_copy(ev);
				this.answered=questionnaire.filter(el=>el.pass).length;
				this.$forceUpdate();
			},
			copy(ev){
				copy(ev);
				let id_elt='#'+ev.dataTransfer.getData("text/plain");
				this.answered=questionnaire.filter(el=>el.pass).length;
				$(id_elt+"-copy"+ev.target.id).removeClass('ml-2 ml-1');
			},
			next(){
				$(".square").addClass('reponse').removeClass("min-width");
				next_drag(this);
			},
			initialiser(){
				this.tab_prop=questionnaire[this.index_qst].good_answer.without_last().split(" ").map(el=>{return {mot:el}});
				this.tab_prop.sort_rn();
				this.good_answer=questionnaire[this.index_qst].good_answer.capitalize();
			},
			submit(ev){
				ev.preventDefault();
				if($("#phrase .ans").length<this.tab_prop.length){
					play(au.empty);
					this.empty_response=true;
					this.label="Tu dois classer tous les mots pour faire une phrase";
					return 0;
				}
				$(".square").removeClass("reponse").addClass("min-width").find('label').removeClass('mr-1').prop("draggable",false).find('div');
				this.response=$("#phrase label.ans input").map((el,ind)=>$(ind).val()).get().join(" ").capitalize()+this.good_answer.slice(-1);
				submit(this,questionnaire);
				if(this.trouve){
					$("#phrase .square").last().find("label.ans .mot").append("<span class='valid'></span>");
				}else{
					$("#phrase .square").last().find("label.ans .mot").append("<span class='fail'></span>");
				}
			},
			restart(){location.reload();}
		},
		watch:{},
		created(){
			this.tab_response=[];
			this.initialiser();
			exercice_vue=this;
		}};
</script>