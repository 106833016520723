<template>
	<div class="row flex-column-reverse flex-md-row" id="select-good-word-from-list">
		<div class="col-md-9 text-center">
			<form class="inner not-solution" id="game">
				<div class="question" id="question">
					<div id="libelle">{{libelle}}<br>
						<span v-for="item in tab_prop" class="word-libelle"><span :class="{barre:item.use}">{{doubleWord?item.alt:item.mot}}</span></span>.<br>
					</div>
					<div>
						<ol class="option-reponse text-left" id="liste-phrases" style="font-size: 1.2em">
							<li class="corps pr-0 phrases" :id="'phrase'+i" v-for="(item,i) in form">
								<label :for="'input-'+i" class="phrases">
									{{item.part[0]}}
									<select :id="'input-'+i" :disabled="trouve||fail_response" class="col-auto px-0 input-mots mot" @change="set_mots" @focus="set_old">
										<option value="none" selected="" disabled="true"></option>
										<option v-for="(it,j) in tab_prop" :value="doubleWord?it.mot+'-'+i+j:it.mot" :id="'option-'+i+j" :class="['option-i'+j,'option-j'+i,{'text-warning':it.use}]">{{doubleWord?it.alt:it.mot}}</option>
									</select>{{item.part[1]}}
								</label>
							</li>
						</ol>
					</div>
					<div id="comment" v-if="empty_response">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed" :matiere="matiere"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/*
*
*here libelee is prop.
*questonnaire=[{part:[],mot:""}]
*
*/
	export default{
		props:{
			libelle:{required:false, type:String, default:"Choisis le mot qui convient parmi ceux qui suivent."},
			doubleWord:{required:false, type:Boolean, default:false},
			multiUse:{required:false, type:Boolean, default:false}
		},
		data(){
			let data=datas(questionnaire);
			return data;
		},
		created(){
			if(this.doubleWord){
				this.tab_prop=tab_prop;
			}else{
				this.tab_prop=questionnaire.map(el=>el.mot).duplicate().map(el=>{return {mot:el, use:false}}).sort_rn();
			}
			exercice_vue=this;

		},
		methods:{
			set_mots(ev){
				if(this.multiUse){
					return 0;
				}
				if(this.doubleWord){
					let txt=$(ev.target).find('option:selected').text(), tab_mot=this.tab_prop.map(el=>el.alt), index=tab_mot.indexOf(txt);
					if(index>=0){
						this.tab_prop[index].use=true;
						$(".option-i"+index).addClass("text-warning");
					}
					this.answered=$("#liste-phrases select.mot").has("option:selected").length;
					this.tab_prop.map(el=>el.use=false);
					$("#liste-phrases select option:selected").map((i,el)=>{
						index=tab_mot.indexOf($(el).text());
						if(index>=0){
							this.tab_prop[index].use=true;
						}
					});
				}else{
					let index=this.tab_prop.map(el=>el.mot).indexOf(ev.target.value);
					if(index>=0){
						this.tab_prop[index].use=true;
					}
					this.tab_prop.map(el=>el.use=false);
					$("#liste-phrases select").map((i,el)=>{
						this.tab_prop.map((e,i)=>{if(e.mot==$(el).val())e.use=true; });
					});
				}
				this.$forceUpdate();
			},
			set_old(ev){
				if(this.multiUse){return 0;}
				if(this.doubleWord){
					let tab_mot=this.tab_prop.map(el=>el.alt), txt=$(ev.target).find('option:selected').text(), index=tab_mot.indexOf(txt);
					if(index>=0){
						this.tab_prop[index].use=false;
						$(".option-i"+index).removeClass("text-warning");
					}
					this.tab_prop.map(el=>el.use=false);
					$("#liste-phrases select option:selected").map((i,el)=>{
						index=tab_mot.indexOf($(el).text());
						if(index>=0){
							this.tab_prop[index].use=true;
						}
					});
				}else{

					//*
						this.tab_prop.map(el=>{if(el.mot==ev.target.value)el.use=false;});
						this.tab_prop.map(el=>el.use=false);
						$("#liste-phrases select").map((i,el)=>{
							this.tab_prop.map(e=>{if(e.mot==$(el).val()) e.use=true;});
						});
						//*/
				}
				this.$forceUpdate();
			},
			submit(ev){
				ev.preventDefault();
				this.empty_response=false;
				$("#liste-phrases select").removeClass('border-warning empty-response');
				if($("#liste-phrases select").not(":has('option:selected')").addClass('border-warning empty-response').length>0){
					this.empty_response=true;
					this.label="Tu dois remplir toutes les cases vides!";
					play(au.empty);
					return 0;
				}
				let submited=$("#liste-phrases select").map((el,ind)=>$(ind).val()).get(),reponses=[];
				if(this.doubleWord){submited=submited.map(el=>el.slice(0,el.indexOf('-')));}
				;
				for(let i=0;i<submited.length;i++){
					let txt=$("#input-"+i).find("option:selected").text();
					if(submited[i]==questionnaire[i].mot){
						$("#input-"+i).addClass('text-success').css('opacity',0.9).after("<span class='valid mr-2 ml-n4'></span>");
						this.correct_answer++;
					}else{
						if(!this.doubleWord){
							txt=questionnaire[i].mot;
						}else{
							for(let j=0;j<this.tab_prop.length;j++){
								if(this.tab_prop[j].mot===questionnaire[i].mot&&!reponses.includes(this.tab_prop[j].mot)){
									txt=this.tab_prop[j].alt;
									break;
								}
							}
						}
						if($("#input-"+i).hasClass("maj")){
							$("#input-"+i).addClass('barre').after("<strong class='text-success ml-1 maj'>"+txt+"</strong>");
						}else{
							$("#input-"+i).addClass('barre').after("<strong class='text-success ml-1'>"+txt+"</strong>");
						}
					}
					reponses.push(txt);
				}
				if(this.fail_response=submited.length>this.correct_answer){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			}
		},
		watch:{
			response(n,o){
				this.answered=$("#liste-phrases label.phrases select").map(function(index, elem) {
					if($(elem).val()){
						return index;
					}
				}).get().length;
			}
		}};
</script>