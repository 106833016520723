<template>
	
	<a :class="['card col-md card-item',donnees.class]" :href="donnees.link" :target="blank?'blank':''" :rel="blank?'noopener noreferrer':''">
		<div class="card-header px-0 text-center" v-if="typeof (donnees.title)!='undefined' && donnees.title.length>0">
			<!-- <h4 :class="donnees.h3">{{donnees.title}}</h4> -->
			<h4 v-html="donnees.title" v-if="donnees.link.length>0"></h4>
			<div v-else>
				<google-auto type="ad"></google-auto>
			</div>
		</div>
		<img :src="'/public/assets/'+donnees.src+'.png'" :alt="donnees.alt" class="card-img-top" v-if="donnees.src.length>0">
		<p class="card-text">
			<slot></slot>
		</p>
		<div class="card-footer" v-html='donnees.footer' v-if="typeof (donnees.footer)!='undefined' &&donnees.footer.length>0"></div>
	</a>
</template>
<script>
	export default{
		props:{
			datas:{type:String,required:true},
			blank:{type:Boolean, required:false, default:false}
		},
		created(){
			// this.donnees={title:this.donnee.title, link:this.donnee.link, src:this.donnee.src}
		},
		mounted(){
			this.ready=true;
		},
		data(){
			return {
				donnees:JSON.parse(this.datas),
				ready:false
			};
		}
	};
</script>