<template>
	<div class="row flex-column-reverse flex-md-row" id="image-input-column">
		<div class="col-md-9 text-centerd-flex">
			<form class="inner" id="game" @submit="submit(event)" action="#_" autocomplete="off">
				<div class="question">
					<!-- <div id="libelle" v-html="libelle"></div> -->
					<div class="">
						<div class="d-flex justify-content-center">
							<div class="col-5" style="height:480px">
								<div class="rounded-bottom bg-fond-1 px-2 pb-2 h-100">
									<div class="bg-fond-2 rounded-bottom p-2 h-100">
										<div class="d-flex flex-column h-100">
											<div class="rounded-bottom" style="background:#e0d7a5">
												<h2 class="text-center p-3 mx-3" style="color:#8c7058!important">{{libelle}}</h2>
											</div>
											<div style="background:#d5efff; border-radius:20px;" class="p-2 flex-fill">
												<div class="d-flex flex-column justify-content-between bg-white h-100" style="border:2px solid; border-left-color #595644; border-right-color:#b3ad88; border-bottom-color:#b3ad88; border-top-color #595644;">
													<div>
														<img :src="'/public/assets/images/fr/'+form[index_qst].src" alt="Image non disponnible." class="w-100 img-fluid">
													</div>
													<div class="form-group p-2 my-2">
														<label for="answer" class="mb-0">Ecris ta réponse ici...</label>
														<input v-if="type=='number'" :type="type" name="answer" id="response" v-model="response" class="form-control text-center" style="font-size:1.4em" :max="max" :min="min" :readonly="trouve||fail_response">
														<input v-else :type="type" name="answer" id="answer" v-model="response" class="form-control text-center">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<solution-exercice :fail-response="fail_response" :answer="form[index_qst].answer.toString()"></solution-exercice>
					</div>

					<div id="comment" class="mt-3" v-if="empty_response">{{label}}</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :score="score" :passed="passed" :nbre-question="nbre_question" :submit="submit" :next="next"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*tab_prop:[""] list of words wich we can remove from text
*
*text:""
*propositons:undefined
*
*
*/
	export default{
		props:{
			libelle:{required:false, default:"Complète la collection pour qu'elle corresponde au nombre associè.", type:String},
			max:{type:Number, required:false, default:100},
			min:{type:Number, required:false, default:1},
			type:{type:String, required:false, default:'number'}
		},
		data(){
			let data=datas(questionnaire);
			data.nbre_question=questionnaire.length;
			data.label="Tu dois ajouter ou diminuer des éléments dans la case.";
			return data;
		},
		created(){
			exercice_vue=this;
			this.initialiser();
		},
		methods:{
			next(){
				next_qst(this);
			},
			initialiser(){

			},
			submit(ev){
				ev.preventDefault();
				if(this.response==''){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				if(this.response==questionnaire[this.index_qst].answer){
					this.correct_answer++;
					this.trouve=true;
					play(au.success);
				}else{
					this.fail_response=true;
					play(au.fail);
				}
				make_score(this);
			}
		},
		watch:{}
	};
</script>