<template>
	<div class="row flex-column-reverse flex-md-row" id="select-on-second-cel-table">
		<div class="col-md-9 text-center">
			<form class="inner not-solution" id="game">
				<div class="question" id="question">
					<div id="libelle"><div v-html="libelle"></div><br>
						<em><span v-for="item in tab_prop" class="word-libelle"><span>{{item}}</span></span></em>
					</div>
					<div>
						<table class="table text-left table-bordered border-perso">
							<thead><tr class="table-info"><th></th><th></th></tr></thead>
							<tbody id="phrases">
								<tr class="corps pr-0" :id="'phrase'+i" v-for="(item,i) in form">
									<td class="py-1 pl-2">{{item.part[0]}}</td>
									<td class="phrases py-1">
										<select :id="'input-'+i" :disabled="trouve||fail_response" v-model="tab_response[i]" class="input-pronoms mot" @change="set_answered">
											<option value="" disabled="true"></option>
											<option v-for="(item,i) in tab_prop" :value="item">{{item}}</option>
										</select>{{item.part[1]}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div id="comment" v-if="empty_response">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
	export default{
		data(){
			let data=datas(questionnaire);
			data.libelle=libelle;
			return data;
		},
		methods:{
			down(ev){
			},
			submit(ev){
				ev.preventDefault();
				$("#phrases .phrases").removeClass('border-warning');
				this.empty_response=false;
				$("#phrases .phrases").each(function(index, el) {
					if(!$(el).find('input').val()){
						$(el).addClass('border-warning');
					}
				});
				if(this.tab_response.includes(undefined)){
					this.label="Tu dois remplir toutes les cases vides!";
					play(au.empty);
					return 0;
				}
				let failed=0;
				for(let i=0;i<this.tab_response.length;i++){
					if(this.tab_response[i].toLowerCase()===questionnaire[i].answer.toLowerCase()){
						$("#phrases .phrases").eq(i).find('.input-pronoms').addClass('cadre').after('<span class="valid ml-n2"></span>')
						this.correct_answer++;
					}else{
						$("#input-"+i).addClass('barre').after("<strong class='text-success mot cadre'>"+questionnaire[i].answer+" </strong>");
						failed++;
						this.fail_response=true;
						this.trouve=false;
					}
				}
				if(this.fail_response){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			},
			set_answered(){
				this.answered=$("#phrases .phrases").map(function(index, elem) {
					if($(elem).find('.input-pronoms').val()){
						return index;
					}
				}).get().length;
			}
		},
		computed:{
		},
		created(){
			this.tab_prop=tab_prop;
			this.tab_response=new Array(questionnaire.length);
		},
		watch:{}};
</script>