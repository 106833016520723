<template>
	<div class="row flex-column-reverse flex-md-row" id="categorise-words-of-sentences-table">
		<div class="col-md-9">
				<form class="inner multi not-solution" id="game">
					<div class="question" id="question">
						<div id="libelle" v-html="libelle"></div>
						<table class="table table-bordered border-perso">
							<thead>
								<tr class="text-center">
									<th ondragover="alowDrop(event)" ondrop="remove(event)" class="text-center text-danger w-50 border-danger">
										<i class="fa fa-trash" scope="col"></i>
									</th>
									<th v-for="cel in tableCels" :class="['table-info th-'+cel]" :style="{width:50/tableCels.length+'%'}">{{cel}}</th>
								</tr>
							</thead>
							<tbody id="phrases" class="input-field">
								<tr v-for="(ite,i) in form" :id="'phrase-'+i" class="phrase">
									<td class="px-0 py-1 pl-2" :id="'groupe-'+i">
										<label :class="['label groupe px-0',classWords(item),{ans:!ponctuation.includes(item)}]" v-for="(item,j) in tab_prop[i]" :id="'lbl-'+i+j" :draggable="!ponctuation.includes(item)&&!(trouve||fail_response)" ondragstart="drag(event)"><input type="text" name="s1" :value="item"><div class="mot" :id="'mot-'+i+j">{{item}}</div></label>
									</td>
									<td v-for="cel in tableCels.map(el=>get_id_cel(el))" :class="['reponse px-0 py-1 pl-2 lbl','reponse-'+cel]" id="'reponse-'+cel+'-'+i" @dragover.sels="alowDrop" @drop.self="drop"></td>
								</tr>
							</tbody>
						</table>
						<div id="comment" v-if="empty_response">{{label}} </div>
					</div>
				</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire:[{phrase:"", answer:"", type:tabCels[i]}]
*
*tabCels:[""]
*
*
*/
	export default{
		props:{
			libelle:{type:String, required:false, default:"Lis attentivement puis glisse la réponse dans la case correspondante"}
		},
		data(){
			questionnaire.sort_rn();
			let data=datas(questionnaire);
			data.tableCels=tableCels;
			return data;
		},
		created(){
			exercice_vue=this;
			this.tab_prop=questionnaire.map(el=>el.phrase.split(" "));
		},
		methods:{
			drop(ev){
				ev.preventDefault();
				let id_elt=ev.dataTransfer.getData("text/plain"),
				 elt=$("#"+id_elt),
				 question=$(ev.target).parent().find(".reponse"),
				 source=$("#"+event.dataTransfer.getData("text/parent"));
				if(question.parent().id()!==source.parent().id()){
					return 0;
				}else{
					if(!source.id().includes("groupe")){
						elt.removeClass("border").detach().appendTo(ev.target);
						return 0;
					}else{
						if($(ev.target).find("label.groupe").length==0&&question.find('label.groupe').length>0){
							return 0;
						}
						let copy=elt.clone();
						if($(ev.target).find("label.groupe").length>0){
							copy.find(".mot").text("\xa0"+elt.text());
						}copy.id(id_elt+"-copy");
						copy.removeClass("border").appendTo(ev.target);
					}
				}
				this.answered=$("td.reponse").has("label.ans").length;
			},
			submit(ev){
				ev.preventDefault();
				let submited=[];
				this.empty_response=false;
				if($("#phrases tr").not(":has(.reponse label.ans)").length>0){
					play(au.empty);
					this.label="Tu dois renseigner une réponse pour chaque phrase";
					return 0;
				}
				let ca=0, fr=false;
				$("#phrases tr").each(function(index, el) {
					let ans=$(el).find('.reponse-'+questionnaire[index].type+' label.ans input').map((ind,elem)=>$(elem).val()).get().join(" ");
					if(questionnaire[index].answer.split("/").includes(ans)){
						$(el).find('.mot').last().after("<span class='valid ml-n1'></span>");
						ca++;
					}else{
						fr=true;
						$(el).find('.reponse .mot').addClass('barre');
						$(el).find('.reponse-'+questionnaire[index].type)
							.append("<div class='good text-success text-center'>"+questionnaire[index].answer.split('/').join(' | ')+"</div>");
					}
				});
				this.correct_answer=ca;
				if(this.fail_response=fr){
					play(au.fail);
				}else{
					this.trouve=true;
					play(au.success);
				}
				make_score(this);
			}
		}
	};
</script>