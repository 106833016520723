<template>
	<div class="track col-md-3 text-center order-last">
		<div class="d-flex flex-column justify-content-between h-100 py-2">
			<div class="row p-0 mx-0" style="color: #fff!important">
				<div class="track-card answered col-sm-6 p-0">
					<h4 class="p-2" style="color: #fff!important">Questions Trouvées</h4>
					<h3 id="answered" style="color: #fff!important;font-size:1.5em">{{correctAnswer}}/{{answered}}</h3>
				</div>
				<div class="track-card score col-sm-6 p-0">
					<h4 class="p-2 row text-center justify-content-center" style="color: #fff!important">Score <br class="d-none d-md-block">Total</h4>
					<h3 id="score" style="color: #fff!important;font-size:1.5em">{{score}}/100</h3>
				</div>
			</div>
			<div v-if="adTrack">
				<google-auto-ad></google-auto-ad>
			</div>
			<div class=" d-none d-lg-block">
				<div :class="['track-card medailles d-flex flex-column mt-2','bg_'+Math.floor(score/25)]" id="trophe-track">
					<h4 class="p-2" style="color: #fff!important">Titre</h4>
					<img :src="'/public/assets/images/badges/badge-'+Math.floor(score/25)+'.png'" class="my-auto mx-auto p-2" alt="Badge de ton titre">
					<div id="level-score" class="w-100 py-1 mt-auto"> {{(score<25)?'Novice':(score<50)?'Apprenti':(score<75)?'Challenger':(score<100)?'Champion':'Légende'}} </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			answered:{type:Number, required:true, default:0},
			score:{type:Number, required:true, default:0},
			correctAnswer:{type:Number, required:true, default:0},
			adTrack:{type:Boolean, required:false, default:false}
		},
		data(){return {}}
	};
</script>