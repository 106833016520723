<template>
	<div class="row flex-column-reverse flex-md-row" id="choose-x-to-complete-phrase">
		<div class="col-md-9">
				<form class="inner multi" id="game">
					<div class="question" id="question">
						<div id="libelle" v-html="libelle"></div>
						<table class="table table-bordered">
							<thead>
								<tr>
									<th class="table-info border-perso">Phrases</th>
								</tr>
							</thead>
							<tbody id="phrases" class="input-field">
								<tr v-for="(item,i) in form" class="phrases">
									<td class="row no-gutters border-perso py-1" style="background-color: rgb(255, 253, 210);">
											<div :id="'phrase-'+i" class="col-lg mr-n2 phrase">{{without_last(item.phrase)}}<span :id="'empty-complete-'+i" class="empty-complete"></span></div>
											<div class="col-auto row mx-0 propos" :id="'liste-prop-'+i">
												<label class="ans border col-auto my-0 ml-1 px-0" v-for="(it,j) in tab_prop[i]" :id="'ponct-'+i+j"><input type="radio" :name="'s'+i" :value="it" @change="set_ponctuation" :disabled="trouve||fail_response"><div class="mot imgp px-2">{{it}}</div></label>
											</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div id="comment" v-if="empty_response" class="mt-3">{{label}} </div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="answered"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*Dans ce modèle d'exercice l'internaute est amené à selectionner un élément dans une liste horizontale sur la même ligne que le phrase
*
*@tab_prop est le tablean de n tableaux contenant les éléments à choisir
*@questionnaire
*
*/
	export default{
		props:{libelle:{type:String, required:false, default:"Pour chacune des phrases, sélectionne l'élément qui convient"}},
		data(){
			let data=datas(questionnaire);
			data.tab_prop=tab_prop;			return data;
		},
		methods:{
			submit(ev){
				ev.preventDefault();let s=0;
				this.empty_response=false;
				$("#phrases td").removeClass('border-warning');
				$("#phrases .propos").each((index, el)=> {
					if($(el).has("input:checked").length>0){s++;}else{
						$(el).parent().addClass('border-warning');
					}
				});
				if(s!=questionnaire.length){
					this.empty_response=true;
					play(au.empty);
					this.label="Sélectionne une ponctuation pour chaque phrase.";
					return 0;
				}
				let submited=$("#phrases td .propos input:checked").map(function(ind, elem) {
					return $(elem).val();
				}).get();
				for(let i=0;i<submited.length;i++){
					let t=$("#phrase-"+i).text();
					if(submited[i]===questionnaire[i].phrase.slice(-1)){
						this.correct_answer++;
						$("#liste-prop-"+i+" label").has('input:checked').addClass('valid');
					}else{
						this.fail_response=true;
						$("#liste-prop-"+i+" label").has('input:checked').addClass('fail');
					}
					$("#phrase-"+i).html(t.without_last()+"<strong class='text-success'>"+questionnaire[i].phrase.slice(-1)+"</strong>");
					$("#liste-prop-"+i+" label").eq(this.tab_prop.indexOf(questionnaire[i].phrase.slice(-1))).find('.mot').addClass('cadre');
				}
				if(this.fail_response){
					play(au.fail);
					this.comment_faux="Oupss! Tu as manqué "+(questionnaire.length-this.correct_answer)+" questions.";
				}
				make_score(this);
				$(".imgp").removeClass('bg-success');
			},
			without_last(text){
				return text.toString().without_last();
			},
			set_ponctuation(event){
				let parent=$(event.target).parent().parent().parent().find(".phrase");
				parent.find(".empty-complete").text($(event.target).val())
				this.answered=$("#phrases tr").has("input:checked").length;
			},
		},
		watch:{},
		created(){
			this.comment_faux="";
			exercice_vue=this;
		}};
</script>