<template>
	<div>
		<div v-if="type=='ad'">
			<google-auto-ad></google-auto-ad>
		</div>
		<div v-else>
			<google-auto-text></google-auto-text>
		</div>
	</div>
</template>
<script>
	export default{
		props:{
			type:{type:String,required:false, default:'ad'}
		}
	};
</script>