<template>
	<div class="text-center" v-if="end">
		<div id="trophes" class="p-4">
			<div id="badges" class="row mx-0 h-100">
				<div class="p-0 d-flex">
					<img src="/public/assets/images/badges/badge-0.png" alt="0" class="mr-auto">
				</div>
				<div class="col p-0" v-for="i in 4">
					<img :src="'/public/assets/images/badges/badge-'+i+'.png'" :alt="i*25" v-if="score>=i*25">
					<img :src="'/public/assets/images/badges/badge-'+i+'-g.png'" :alt="(i)*25" v-else>
				</div>
			</div>
			<div class="progress w-100">
				<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="10" aria-valuemax="100" :style="{width: score+'%'}" id="score-progress-bar">
				</div>
			</div>
		</div>
		<div v-if="index_exo>=nbre_exo&&score>=60">
			<h5 class="text-info">Tu as terminé avec les exercices de ce chapitre.</h5>
			<div class="text-right"><a :href="'/programme/'+matiere" class="link" target="blank"><i class="fa fa-outdent"></i>Exercices de {{matiere}}</a></div>
			
		</div>
	</div>
</template>
<script>
	export default{
	props:{
		score:{type:Number, dafault:0},
		end:{type:Boolean, default:true},
		nbre_exo:{type:Number, required:false, default:3},
	},
	data(){return{index_exo:index_exo,matiere:matiere}},
	created(){
	},
	methods:{set_exo(){this.$emit("exo",math.min(nbre_exo,this.index_exo+1))}}
	};
</script>