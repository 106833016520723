<template>
	<div class="row flex-column-reverse flex-md-row" id="drag-word-to-build">
		<div class="">
			<form class="inner multi" id="game">
				<div class="question" id="question">
					<div id="libelle" v-html="libelle"></div>
					<div>
						<div class="d-flex justify-content-center align-content-center">
							<div class="flex-fill bg-dark p-1">
								<img src="form[index_qst].img1" alt="comparaison-collection-1">
							</div>
							<div class="mx-1 align-self-center bg-dark shadow rounded" style="height:3rem; min-width:3rem">
								<div id="signe" class="h-100 w-100" @drop.self="move" @dragover.self="alowDrop"></div>
							</div>
							<div class="flex-fill bg-dark p-1">
								<img :src="form[index_qst].img2" alt="comparaison-collection-2">
							</div>
						</div>
					</div>
					<div id="comment" v-if="empty_response" class="mt-3">{{label}} </div>
					<div class="row no-gutters justify-content-center">
						<label v-for="(item,i) in tab_prop" :for="'input-'+i" :draggable="!(trouve||fail_response)" @dragstart.self="drag" class="ans" :id="'lbl-'+i">
							<input type="text" :name="'input-'+i" readonly="true" :value="item.value">
							<div>{{item.sign}}</div>
						</label>
					</div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :next="next" :nbre-question="nbre_question" :score="score" :passed="answered" :matiere="matiere"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>

/****
*
*
*questionnaire=[{img1:'', img2:'', answer:''}]
*
*
*
*
*/

	export default{
		props:{
			libelle:{type:String, default:"Complète avec les signes <, > ou = !", required:false},
			withEqual:{type:Boolean, required:false, default:false}
		},
		data(){
			let d=datas(questionnaire);
			d.matiere=matiere;
			d.nbre_question=questionnaire.length;
			d.tab_prop=[{value:'greatter-than',sign:'>'},{value:'lower-than',sign:'<'}];
			return d;
		},
		methods:{
			move(ev){
			},
			next(){
				$("#signe label.ans").detach().appendTo('#liste-signe');
				next_qst(this);
			},
			initialiser(){
			},
			submit(ev){
				ev.preventDefault();
				if($("#signe label.ans").length<1){
					this.empty_response=true;
					play(au.empty);
					return 0;
				}
				if(questionnaire[this.index_qst].answer==$('#signe label.ans input').val()){
					play(au.success);
					this.correct_answer=true;
				}else{
					this.fail_response=true;
					play(au.fail);
					$("#signe label.ans").detach().appendTo('#liste-signe').find('div').addClass('text-danger');
					$("#signe").append($('#liste-signe label.ans').filter((i,el)=>$(el).find('input').val()==questionnaire[this.index_qst].answer).clone());
				}
			},
		},
		watch:{},
		created(){
			if(this.withEqual){
				this.tab_prop.push({value:'equal',sign:'='});
			}
			this.initialiser();
			exercice_vue=this;
		}};
</script>