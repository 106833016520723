<template>
	<div>
		<div class="py-3 px-2 text-left" style="text-align-last:initial" id="choose-good-text-for-text">
			<div class="main-quiz-holder">
				<div class="slide-container d-block py-3">
					<div class="py-3 px-3">
						<head-exercise :afficher-reponse="afficheReponse" :reponse-vraie='reponseVraie' :reponse-fausse="reponseFausse" :rejouer="rejouer"></head-exercise>
						<div class="code2">
							<div id="question">
								<div class="featureText1 ml-5" v-html="question"></div><br><br>
								
								<div class="form-group row no-gutters ml-5 align-items-center">
									<input type="text" autofocus="autofocus" class="form-control text-center col-md-3" @keyup="controlInput" v-model="reponse" id="input-reponse" :readonly="repondu" :disabled="repondu" autocomplete="off">
									<label for="input-reponse" class="mb-0 mx-1">{{unite}}</label>
								</div>
							</div>
							<div class="result-container notice col-md-5" v-show='reponseVide'>Entre une réponse valide!</div>
						</div>

						<div style="font-size:1.2em">
							<p style="color:#999;"><em v-html="notice"></em></p>
							<p class="my-3" v-show="afficherReponse">
								La bonne réponse est : <strong class="text-success" v-html="bonneReponse"></strong> {{unite}}
							</p>
						</div>
						<div class="nav-container d-flex justify-content-center">
							<button class="btn next final" @click="soumettre" type="button"> Valider </button>
						</div>
						
					</div>
					<navigation-frame :question-suivante="questionSuivante"></navigation-frame>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/*
*
*questionnaire:[{libelle:'',solution:x, unite:}]
*
*
*
*
*
*/
	export default{
		props:{
			input:{type:Boolean, required:false, default:false},
			notice:{type:String, required:false, default:''}
		},
		data:function () {
			return {
				question:'',
				operandes:[],
				reponse:'',
				reponseVide:false,
				reponseFausse:false,
				reponseVraie:false,
				bonneReponse:0,
				repondu:false,
				index:0,
				afficherReponse:false,
			}
		},
		created(){
			this.initialiser();
		},
		methods:{
			questionSuivante(e){
				this.rejouer();
				this.index=e==1? (this.index+1) % questionnaire.length: 
					e==-1? Math.max(this.index-1,0):
					rn(0,questionnaire.length-1) ;	
				this.initialiser();
			},
			initialiser(){
				let i=this.index;
				if(this.input){
				}else{
					this.propositions=questionnaire[i].propositions;
				}
				this.unite=questionnaire[i].unite;
				this.bonneReponse=questionnaire[i].solution;
				this.question=questionnaire[i].libelle;
			},
			rejouer(){
				this.reponse='';
				this.reponseFausse=false;
				this.reponseVraie=false;
				this.reponseVide=false;
				this.repondu=false;
				this.afficherReponse=false;
				document.getElementById('input-reponse').focus();
			},
			soumettre(ev){
				if(this.reponse==''){
					this.reponseVide=true;
					play(au.empty);
					return 0;
				}
				this.repondu=true;
				if(this.reponse==this.bonneReponse){
					this.reponseVraie=true;
					play(au.success);
				}else{
					console.log('Soumis: ',this.reponse,this.bonneReponse);
					this.reponseFausse=true;
					play(au.fail);
				}
			},
			controlInput(ev){
				if((ev.keyCode<48 || ev.keyCode>57 )){
					ev.preventDefault();
				}
			},
			afficheReponse(){
				this.afficherReponse=true;
			}
		},
		watch:{
		}
	};
</script>