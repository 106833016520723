<template>
	<div class="row flex-column-reverse flex-md-row" id="variant-words-table">
		<div class="col-md-9 text-center mr-auto">
			<form class="inner multi not-solution" id="game">
				<div class="question">
					<div id="libelle" class="row p-2" v-html="libelle"></div>
					<table id="maison" class="col-lg table table-bordered border-perso">
						<header-cels :table-cels="tableCels">
							<th class="table-info" v-if="fail_response">Réponses</th>
						</header-cels>
						<tbody class="corps input-field" id="body-reponse">
							<tr v-for="(item,i) in form" :id="'tr-'+i">
								<td :rowspan="form.length" v-if="i==0" class="">
									<div id="liste-propos" class="d-flex flex-column justify-content-around">
										<label class="col-auto noms ans border p-0 mx-1" v-for="(item,i) in tab_prop" :draggable="!item.use&&!(trouve||fail_response)" ondragstart="drag(event)" :id="'lbl-'+i">
											<input type="text" :value="item.mot" name="s1" :readonly="true"/>
											<div :class="['mot py-1',{barre:item.use}]">{{item.mot}}</div>
										</label>
									</div>
								</td>
								<td v-for="(item,j) in tableCels.map(el=>get_id_cel(el))" @dragover="bool_drop(item,i,$event)" @drop.self="drop" :class="[form[i].given.includes(item)?'given':'lbl reponse','td-'+get_id_cel(item)]" :id="'reponse-'+item+'-'+i"><div v-if="form[i].given.includes(item)">{{form[i][item]}}</div></td>
								<td v-show="fail_response" class="text-success">{{tableCels.filter(el=>!form[i].given.includes(get_id_cel(el))).map(el=>form[i][get_id_cel(el)]).join(' | ')}}</td>
							</tr>
						</tbody>
					</table>
					<div id="comment" v-html="label" class="my-2"></div>
				</div>
			</form>
			<bottom-section :fail="fail_response" :trouve="trouve" :end="end" :submit="submit" :score="score" :passed="passed"></bottom-section>
		</div>
		<trackcard :answered="answered" :score="score" :correct-answer="correct_answer"></trackcard>
	</div>
</template>
<script>
/*
*
*questionnaire:[{cel1:"",cel2:""---},{cel1:"",cel2:"",---},---]
*
*
*
*
*
*/
	export default{
		props:{libelle:{type:String, required:false, default:"Compltéte les cases vides en glissant un mot"}},
		data:function () {
			questionnaire=questionnaire.map(el=>{
				el.given=tableCels.slice().sort_rn().slice(1).map(e=>get_id_cel(e));
				return el;
			});
			let  data=datas(questionnaire);
			data.tableCels=tableCels;
			return data;
		},
		methods:{
			submit(ev){
				ev.preventDefault();
				$("td.reponse").has("label.ans").removeClass('border-warning');
				if($("#body-reponse td.reponse").not(":has(label.ans)").addClass('border-warning').length>0){
					this.empty_response=true;
					this.label="Tu dois remplir toutes les cases vides";
					play(au.empty);
					return 0;
				}
				let ca=0,fr=false;
				$("#body-reponse tr").map(function(index,elem){
					let cel,tab=tableCels.map(el=>get_id_cel(el)).filter(el=>!questionnaire[index].given.includes(el));
					for(cel of tab){
						if($(elem).find(".td-"+cel+' label.ans input').val()==questionnaire[index][cel]){
							ca++;
							$(elem).find('.td-'+cel+' .mot').last().after("<span class='valid ml-n2'></span>");
						}else{
							fr=true;
							$(elem).find('.td-'+cel+' .mot').addClass('barre');
						}
					}
				});
				this.correct_answer=ca;
				if(this.fail_response=fr){
					play(au.fail);
					play(au.fail)
				}else{
					this.trouve=true;
					play(au.success);
				}
			    make_score(this);
			    
			},
			drop(event){
				copy(event)
			},
			bool_drop(el,i,ev){
				if(questionnaire[i].given.includes(el)){
					return false;
				}else{
					return alowDrop(ev);
				}
			}
		},
		computed:{	
		}, 
		created(){
			exercice_vue=this;
			this.tab_prop=questionnaire.map(el=>{
				return tableCels.filter(e=>!el.given.includes(get_id_cel(e))).map(e=>{return {mot:el[get_id_cel(e)]}});
			}).flat().sort_rn();
		}}
		;
</script>